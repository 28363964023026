import styles from "./TextInfo.module.scss";

type TextInfoProps = {
  label: string;
  value: string | number | JSX.Element;
};

export const TextInfo = ({ label, value }: TextInfoProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.text}>{label}</div>
        <div className={styles.text}>{value}</div>
      </div>
    </div>
  );
};
