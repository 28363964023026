import { LeaderboardPosition } from "../redux/models";
import { Leaderboard } from "../redux/responseTypes";

export const parseLeaderboard = (
  leaderboard: Leaderboard
): LeaderboardPosition[] => {
  const parsedLeaderboard = JSON.parse(leaderboard.data)
    .data as LeaderboardPosition[];

  const response: LeaderboardPosition[] = [];
  let rankPosition = 0;
  parsedLeaderboard.forEach(({ players, rank, local_score, city }) => {
    if (typeof players !== "string") {
      rankPosition = rank;
      players.forEach((playerName) => {
        response.push({
          local_score,
          rank: rankPosition,
          players: playerName,
          city,
        });

        rankPosition++;
      });
    }
  });

  return response;
};

/**
 * This returns the today leaderboard, with the position difference compared to yesterday
 *
 * Expects to receive params parsed with the parseLeaderboard function
 * @param yesterday
 * @param today
 * @returns
 */
export const compareLeaderboards = (
  yesterday: LeaderboardPosition[],
  today: LeaderboardPosition[],
  location?: string
): LeaderboardPosition[] => {
  // Since this website only works with mocked data, sometimes it won't find the player on the yesterday
  // leaderboard list, therefore it will use a default value to make it look nice once this player now
  // shows up on the leaderboard
  const DEFAULT_YESTERDAY_PLAYER: LeaderboardPosition = {
    local_score: yesterday[yesterday.length - 1].local_score,
    players: "",
    rank: 101,
    city: "",
  };

  const RANK1_SCORE = today[0].local_score;

  const isWorldLeaderboard = (location?: string) => location === "world";

  return today.map((todayPlayer) => {
    const yesterdayPlayer =
      yesterday.find(
        ({ city: yesterdayCity, players: yesterdayPlayerName }) =>
          ((isWorldLeaderboard(location) &&
            `Yesterday${todayPlayer.city}` === yesterdayCity) ||
            !isWorldLeaderboard(location)) &&
          todayPlayer.players === yesterdayPlayerName
      ) || DEFAULT_YESTERDAY_PLAYER;

    if (yesterdayPlayer.players) {
      return {
        ...todayPlayer,
        positionDifference: yesterdayPlayer.rank - todayPlayer.rank,
        scoreDifference: todayPlayer.local_score - yesterdayPlayer.local_score,
        scoreDifferenceFromTopPlayerScore:
          todayPlayer.local_score - RANK1_SCORE,
      };
    }

    return todayPlayer;
  });
};
