import classNames from "classnames";
import styles from "./NewTag.module.scss";

type NewTagProps = {
  className?: string;
};

export const NewTag = ({ className }: NewTagProps): JSX.Element => {
  const wrapperClassName = classNames(styles.wrapper, className);
  return (
    <span className={wrapperClassName}>
      <strong>NEW</strong>
    </span>
  );
};
