import { Dispatch } from "react";
import { activateAPI } from "../../api";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { StrategyResponse } from "../responseTypes";

export const fetchStrategyGame = (level: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOADING_FETCH_STRATEGY_GAME,
      payload: true,
    });

    try {
      const { data } = await activateAPI.get<StrategyResponse>(
        `/games/strategy/${level}`
      );
      dispatch({
        type: ActionType.FETCH_STRATEGY_GAME_COMPLETE,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ActionType.FETCH_STRATEGY_GAME_ERROR,
        payload: "Error retrieving strategy game",
      });
    } finally {
      dispatch({
        type: ActionType.LOADING_FETCH_STRATEGY_GAME,
        payload: false,
      });
    }
  };
};
