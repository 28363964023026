import styles from "./LeaderboardScoreDifference.module.scss";
import classNames from "classnames";

type LeaderboardScoreDifferenceProps = {
  scoreDifference?: number;
};

export const LeaderboardScoreDifference = ({
  scoreDifference = 0,
}: LeaderboardScoreDifferenceProps): JSX.Element => {
  const isScorePositive = scoreDifference > 0;
  const scoreDifferenceClassNames = classNames(
    styles.scoreDifference,
    isScorePositive ? styles.positive : styles.negative
  );

  return (
    <>
      {Boolean(scoreDifference) && (
        <span className={scoreDifferenceClassNames}>
          ({isScorePositive && "+"}
          {scoreDifference})
        </span>
      )}
    </>
  );
};
