// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveX_removeIcon__9\\+ynw {\n  color: red;\n  font-size: 20px;\n  margin-right: 8px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/RemoveX.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB,EAAA","sourcesContent":[".removeIcon {\n  color: red;\n  font-size: 20px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeIcon": "RemoveX_removeIcon__9+ynw"
};
export default ___CSS_LOADER_EXPORT___;
