import { useState, createRef, useEffect, useMemo } from "react";
import classNames from "classnames";

import styles from "./SearchInput.module.scss";

import { TextInput } from "../TextInput/TextInput";

export type SearchInputProps = {
  onSearchClick: (text: string) => void;
  placeholder?: string;
  buttonLabel?: string;
  error?: string;
  /**
   * Default 'large'
   */
  size?: "large" | "small";
  additionalStyles?: string;
  /**
   * Default 'center
   */
  align?: "center" | "right" | "left";
  focusOnRender?: boolean;
};

export const SearchInput = ({
  onSearchClick,
  buttonLabel = "Search",
  placeholder = "",
  error = "",
  size = "large",
  additionalStyles = "",
  align = "center",
  focusOnRender = false,
}: SearchInputProps): JSX.Element => {
  const inputRef = createRef<HTMLInputElement>();
  const [text, setText] = useState("");

  useEffect(() => {
    if (focusOnRender) {
      inputRef.current?.focus();
    }
  }, [focusOnRender, inputRef]);

  const handleOnSearch = () => {
    onSearchClick(text);
    setText("");
  };

  const inputClassNames = useMemo(
    () => classNames(styles.input, styles[`input-${size}`], styles[size]),
    [size]
  );

  const buttonClassNames = useMemo(
    () => classNames(styles.button, styles[size]),
    [size]
  );

  const wrapperClassNames = useMemo(
    () => classNames(styles.wrapper, additionalStyles),
    [additionalStyles]
  );

  const searchInputWithErrorWrapperClassNames = useMemo(
    () =>
      classNames(styles.searchInputWithErrorWrapper, styles[`align-${align}`]),
    [align]
  );

  return (
    <div className={searchInputWithErrorWrapperClassNames}>
      <div className={wrapperClassNames}>
        <TextInput
          innerRef={inputRef}
          className={inputClassNames}
          placeholder={placeholder}
          onChange={(e) => setText(e.target.value)}
          value={text}
          onKeyDown={(e) => e.key === "Enter" && handleOnSearch()}
        />
        <button className={buttonClassNames} onClick={handleOnSearch}>
          {buttonLabel}
        </button>
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
};
