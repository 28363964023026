// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DropdownSelect_dropdown__IuVPq {\n  padding: 0 16px;\n  border-radius: 26px;\n  font-size: 18px;\n  height: 30px; }\n", "",{"version":3,"sources":["webpack://./src/components/DropdownSelect/DropdownSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,YAAY,EAAA","sourcesContent":[".dropdown {\n  padding: 0 16px;\n  border-radius: 26px;\n  font-size: 18px;\n  height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "DropdownSelect_dropdown__IuVPq"
};
export default ___CSS_LOADER_EXPORT___;
