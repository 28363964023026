// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextInfo_wrapper__3NgFi {\n  font-size: 1.875rem; }\n\n.TextInfo_textWrapper__ZktdC {\n  display: flex;\n  margin-left: 24px; }\n\n.TextInfo_text__Bi0BM {\n  width: 33%;\n  text-align: left;\n  margin-bottom: 24px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Summary/components/TextInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,iBAAiB,EAAA;;AAGnB;EACE,UAAU;EACV,gBAAgB;EAChB,mBAAmB,EAAA","sourcesContent":[".wrapper {\n  font-size: 1.875rem;\n}\n\n.textWrapper {\n  display: flex;\n  margin-left: 24px;\n}\n\n.text {\n  width: 33%;\n  text-align: left;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TextInfo_wrapper__3NgFi",
	"textWrapper": "TextInfo_textWrapper__ZktdC",
	"text": "TextInfo_text__Bi0BM"
};
export default ___CSS_LOADER_EXPORT___;
