import { useWindowWidth } from "./";

const MOBILE_WIDTH = 600 as const;
const TABLET_WIDTH = 960 as const;

type Breakpoints = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export const useResponsiveBreakpoints = (): Breakpoints => {
  const width = useWindowWidth();

  const isMobile = width <= MOBILE_WIDTH;
  const isTablet = width <= TABLET_WIDTH;
  const isDesktop = !isMobile && !isTablet;

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
