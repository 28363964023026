import styles from "./Banner.module.scss";

type BannerProps = {
  type: "alert" | "success";
  message?: string;
  messageComponent?: JSX.Element;
};

export const Banner = ({
  message,
  type,
  messageComponent,
}: BannerProps): JSX.Element => {
  return (
    <div className={styles[type]}>
      <strong>{message || messageComponent}</strong>
    </div>
  );
};
