// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScoreDifference_scoreDifferenceCommon__\\+9Zy5, .ScoreDifference_scoreDifferenceNegative__HjYFX, .ScoreDifference_scoreDifferencePositive__bI\\+AU {\n  margin-left: 4px; }\n\n.ScoreDifference_scoreDifferenceNegative__HjYFX {\n  color: red; }\n\n.ScoreDifference_scoreDifferencePositive__bI\\+AU {\n  color: green; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/ScoreDifference.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;;AAGlB;EAEE,UAAU,EAAA;;AAGZ;EAEE,YAAY,EAAA","sourcesContent":[".scoreDifferenceCommon {\n  margin-left: 4px;\n}\n\n.scoreDifferenceNegative {\n  @extend .scoreDifferenceCommon;\n  color: red;\n}\n\n.scoreDifferencePositive {\n  @extend .scoreDifferenceCommon;\n  color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreDifferenceCommon": "ScoreDifference_scoreDifferenceCommon__+9Zy5",
	"scoreDifferenceNegative": "ScoreDifference_scoreDifferenceNegative__HjYFX",
	"scoreDifferencePositive": "ScoreDifference_scoreDifferencePositive__bI+AU"
};
export default ___CSS_LOADER_EXPORT___;
