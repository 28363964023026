// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_wrapper__f2VLQ {\n  margin-left: 4px; }\n\n.Checkbox_checkbox__71xI9 {\n  margin-right: 8px; }\n\n.Checkbox_newTag__cmos3 {\n  margin-left: 8px; }\n", "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;;AAGlB;EACE,iBAAiB,EAAA;;AAGnB;EACE,gBAAgB,EAAA","sourcesContent":[".wrapper {\n  margin-left: 4px;\n}\n\n.checkbox {\n  margin-right: 8px;\n}\n\n.newTag {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Checkbox_wrapper__f2VLQ",
	"checkbox": "Checkbox_checkbox__71xI9",
	"newTag": "Checkbox_newTag__cmos3"
};
export default ___CSS_LOADER_EXPORT___;
