// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BindedPlayers_wrapper__vm-Y8 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: white; }\n\n.BindedPlayers_label__0CqMN {\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  margin-right: 20px; }\n\n.BindedPlayers_keyInput__b5eVj {\n  margin-right: 20px;\n  text-align: center;\n  width: 25px;\n  font-weight: 600; }\n\n.BindedPlayers_goButton__rzYfV {\n  cursor: pointer;\n  margin-left: 8px;\n  padding: 0 8px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/BindedPlayers/BindedPlayers.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY,EAAA;;AAGd;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,gBAAgB,EAAA;;AAGlB;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc,EAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: white;\n}\n\n.label {\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  margin-right: 20px;\n}\n\n.keyInput {\n  margin-right: 20px;\n  text-align: center;\n  width: 25px;\n  font-weight: 600;\n}\n\n.goButton {\n  cursor: pointer;\n  margin-left: 8px;\n  padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "BindedPlayers_wrapper__vm-Y8",
	"label": "BindedPlayers_label__0CqMN",
	"keyInput": "BindedPlayers_keyInput__b5eVj",
	"goButton": "BindedPlayers_goButton__rzYfV"
};
export default ___CSS_LOADER_EXPORT___;
