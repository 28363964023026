import { Dispatch } from "react";
import { Action } from "../actions/activate";
import { activateAPI } from "../../api";
import { LastUpdated } from "../responseTypes";
import { ActionType } from "../actionTypes";

export const fetchLastUpdated = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await activateAPI.get<LastUpdated>("/lastUpdated");
      dispatch({ type: ActionType.FETCH_LAST_UPDATED, payload: response.data });
    } catch (err) {
      // TODO: store error
    }
  };
};
