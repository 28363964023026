import styles from "./Rank.module.scss";
import classNames from "classnames";

type RankProps = {
  rank: number;
};

export const Rank = ({ rank }: RankProps): JSX.Element => {
  const pentagonClassnames = classNames(styles.pentagon, styles[`rank${rank}`]);
  return (
    <div className={styles.wrapper}>
      <div className={pentagonClassnames}>
        <div className={styles.innerPentagon}>
          <span className={styles.rank}>{rank}</span>
        </div>
      </div>
    </div>
  );
};
