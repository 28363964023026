import { ChangeEvent } from "react";
import styles from "./DropdownSelect.module.scss";
import { getOptionByValue } from "./utils";

export type Option = {
  label: string;
  value: string;
};

type DropdownSelectProps = {
  label?: string;
  selectedOption: Option;
  setSelectedOption: (option: Option) => void;
  options: Option[];
};

export const DropdownSelect = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
}: DropdownSelectProps): JSX.Element => {
  // Handler function to update the selected value
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const newSelectedOption = getOptionByValue(event.target.value, options);

    setSelectedOption(newSelectedOption || selectedOption);
  };

  return (
    <div>
      <label className={styles.label} htmlFor="dropdown">
        {label}
      </label>
      <select
        className={styles.dropdown}
        id="dropdown"
        value={selectedOption.value}
        onChange={handleSelectChange}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
