import { useTypedSelector } from ".";

type UseCompareOldScoresResponse = {
  isComparingOldScores: boolean;
};

export const useCompareOldScores = (): UseCompareOldScoresResponse => {
  const { playerYesterday } = useTypedSelector((state) => state.playerInfo);

  return {
    isComparingOldScores: Boolean(playerYesterday),
  };
};
