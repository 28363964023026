import ReactDOM from "react-dom/client";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.css";
import { store } from "./redux/store";
import { App } from "./App";
// import { Strategy } from "./pages/Strategy/Strategy";
// import { PageNotFound } from "./pages/PageNotFound/PageNotFound";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
