// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters_labelWrapper__3xp0r {\n  margin: 0;\n  cursor: pointer;\n  margin-left: 16px;\n  font-size: 24px;\n  display: flex;\n  align-items: baseline; }\n\n.Filters_label__k5l4z {\n  margin-left: 16px; }\n\n.Filters_modal__UmgjW {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  right: auto;\n  bottom: auto;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  border-radius: 8px;\n  background-color: #1e1e1e;\n  color: white;\n  font-size: 20px;\n  outline: none; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Filters/Filters.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,qBAAqB,EAAA;;AAGvB;EACE,iBAAiB,EAAA;;AAGnB;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,aAAa,EAAA","sourcesContent":[".labelWrapper {\n  margin: 0;\n  cursor: pointer;\n  margin-left: 16px;\n  font-size: 24px;\n  display: flex;\n  align-items: baseline;\n}\n\n.label {\n  margin-left: 16px;\n}\n\n.modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  right: auto;\n  bottom: auto;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  border-radius: 8px;\n  background-color: #1e1e1e;\n  color: white;\n  font-size: 20px;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelWrapper": "Filters_labelWrapper__3xp0r",
	"label": "Filters_label__k5l4z",
	"modal": "Filters_modal__UmgjW"
};
export default ___CSS_LOADER_EXPORT___;
