import styles from "./ListPlayersToRemove.module.scss";

import { RemoveX } from "./RemoveX";
import { useActions, useTypedSelector } from "../../../../../hooks";

export const ListPlayersToRemove = (): JSX.Element => {
  const { comparePlayers } = useTypedSelector(({ playerInfo }) => playerInfo);
  const { removePlayerListPlayers } = useActions();

  return (
    <ul className={styles.list}>
      {comparePlayers.map(({ player_name }, i) => {
        const handleOnClick = () => {
          removePlayerListPlayers(player_name);
        };
        return (
          <li key={i} onClick={handleOnClick}>
            <RemoveX onClick={handleOnClick} /> {player_name}
          </li>
        );
      })}
    </ul>
  );
};
