// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideMenu_commonMenu__tLAvs, .SideMenu_menu__AuTAi, .SideMenu_subMenu__vlcww {\n  text-align: left;\n  list-style-type: none;\n  margin: 0;\n  padding: 0; }\n\n.SideMenu_menu__AuTAi {\n  width: 20%;\n  background-color: rgba(0, 0, 0, 0.3);\n  height: -moz-fit-content;\n  height: fit-content; }\n  .SideMenu_menu__AuTAi li {\n    cursor: pointer;\n    padding: 8px;\n    color: white; }\n    .SideMenu_menu__AuTAi li:hover {\n      background-color: rgba(255, 255, 255, 0.3);\n      color: #ffffffcc; }\n\n.SideMenu_subMenu__vlcww {\n  padding-top: 8px; }\n  .SideMenu_subMenu__vlcww li {\n    padding-left: 48px; }\n\n.SideMenu_active__qlUE8 {\n  background-color: rgba(255, 255, 255, 0.3) !important;\n  color: #ffffff99 !important; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/SideMenu/SideMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,SAAS;EACT,UAAU,EAAA;;AAGZ;EAEE,UAAU;EACV,oCAAoC;EAUpC,wBAAmB;EAAnB,mBAAmB,EAAA;EAbrB;IAKI,eAAe;IACf,YAAY;IACZ,YAAY,EAAA;IAPhB;MASM,0CAA0C;MAC1C,gBAAgB,EAAA;;AAMtB;EAEE,gBAAgB,EAAA;EAFlB;IAII,kBAAkB,EAAA;;AAItB;EACE,qDAAqD;EACrD,2BAA2B,EAAA","sourcesContent":[".commonMenu {\n  text-align: left;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\n.menu {\n  @extend .commonMenu;\n  width: 20%;\n  background-color: rgba(0, 0, 0, 0.3);\n  li {\n    cursor: pointer;\n    padding: 8px;\n    color: white;\n    &:hover {\n      background-color: rgba(255, 255, 255, 0.3);\n      color: #ffffffcc;\n    }\n  }\n  height: fit-content;\n}\n\n.subMenu {\n  @extend .commonMenu;\n  padding-top: 8px;\n  li {\n    padding-left: 48px;\n  }\n}\n\n.active {\n  background-color: rgba(255, 255, 255, 0.3) !important;\n  color: #ffffff99 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonMenu": "SideMenu_commonMenu__tLAvs",
	"menu": "SideMenu_menu__AuTAi",
	"subMenu": "SideMenu_subMenu__vlcww",
	"active": "SideMenu_active__qlUE8"
};
export default ___CSS_LOADER_EXPORT___;
