import { Fragment } from "react";

export type RadioItemValue = string | number;

export type RadioItem = {
  value: RadioItemValue;
  label: string;
};

type RadioGroupProps = {
  name: string;
  value: RadioItemValue;
  onChange: (value: RadioItemValue) => void;
  radioItems: RadioItem[];
  className?: string;
};

export const RadioGroup = ({
  value: currentValue,
  onChange,
  name,
  radioItems,
  className,
}: RadioGroupProps): JSX.Element => {
  return (
    <div className={className}>
      {radioItems.map(({ label, value }, i) => (
        <Fragment key={i}>
          <input
            checked={value === currentValue}
            type="radio"
            id={label}
            name={name}
            value={value}
            onChange={() => onChange(value)}
          />
          <label htmlFor={label}>{label}</label>
        </Fragment>
      ))}
    </div>
  );
};
