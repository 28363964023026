// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_wrapper__nlASx {\n  background-color: #1e1e1e;\n  min-height: 100vh; }\n", "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB,EAAA","sourcesContent":[".wrapper {\n  background-color: #1e1e1e;\n  min-height: 100vh;\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "App_wrapper__nlASx"
};
export default ___CSS_LOADER_EXPORT___;
