import styles from "./SamePosition.module.scss";
import { BsFillStopFill } from "react-icons/bs";

export const SamePosition = (): JSX.Element => {
  return (
    <>
      0 <BsFillStopFill className={styles.samePosition} />
    </>
  );
};
