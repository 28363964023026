import { VscStarFull } from "react-icons/vsc";
import styles from "./Stars.module.scss";

type StarsProps = {
  stars: number;
};

export const Stars = ({ stars }: StarsProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <VscStarFull className={styles.star} />
      <div className={styles.starsCountWrapper}>
        <div className={styles.starsCount}>{stars}</div>
        <div className={styles.text}>Stars</div>
      </div>
    </div>
  );
};
