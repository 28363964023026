import { Dispatch } from "react";

import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { Badge } from "../responseTypes/badge";
import { activateAPI } from "../../api";

export const fetchBadges = () => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({ type: ActionType.FETCH_BADGES_LOADING, payload: true });

    const badges = await activateAPI.get<Badge[]>("/badges");
    dispatch({
      type: ActionType.FETCH_BADGES_COMPLETE,
      payload: badges.data || [],
    });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({ type: ActionType.FETCH_BADGES_LOADING, payload: false });
  }
};

export const sortBadgesByTitle = () => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionType.SORT_BADGES_BY_TITLES });
};

export const sortBadgesByStars = () => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionType.SORT_BADGES_BY_STARS });
};
