import { useState } from "react";
import Modal from "react-modal";

import styles from "./ChooseLocationModal.module.scss";
import { useActions, useTypedSelector } from "../../../../hooks";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import { MenuActive } from "../SideMenu/SideMenu";

type ChooseLocationModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  showDontAskAgainCheckbox?: boolean;
  menuActive: MenuActive;
};

export const ChooseLocationModal = ({
  isModalOpen,
  shouldCloseOnEsc = false,
  shouldCloseOnOverlayClick = false,
  setIsModalOpen,
  showDontAskAgainCheckbox = false,
  menuActive,
}: ChooseLocationModalProps): JSX.Element => {
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const {
    locations,
    player: { player_name },
    playerBeingSearched,
  } = useTypedSelector(({ playerInfo }) => playerInfo);
  const { fetchPlayerInformation, fetchLeaderboard } = useActions();

  const onDontAskAgainClick = () => {
    setDontAskAgain(!dontAskAgain);
  };

  const onLocationClick = (location: string) => () => {
    fetchPlayerInformation(player_name || playerBeingSearched, location);
    (menuActive === "Leaderboards" || menuActive === "World Leaderboard") &&
      fetchLeaderboard(menuActive === "Leaderboards" ? location : "world");
    setIsModalOpen(false);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Select a Location"
      className={styles.modal}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <h2 className={styles.chooseLocation}>Select a Location</h2>
      <ul className={styles.locationsList}>
        {locations.map((location) => (
          <li
            className={styles.button}
            key={location}
            onClick={onLocationClick(location)}
          >
            {location}
          </li>
        ))}
      </ul>
      {showDontAskAgainCheckbox && (
        <Checkbox
          additionalStyles={styles.checkbox}
          value={dontAskAgain}
          onValueChange={onDontAskAgainClick}
          label="Don't ask again"
          data-trackid="don't ask again (location)"
        />
      )}
    </Modal>
  );
};
