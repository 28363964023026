import { useEffect, useState } from "react";

import { Filters, GamesList } from "../components/GamesList";
import { GameCardList } from "../components/GameCardList";
import { Game } from "../../../../../redux/models";
import { useTypedSelector } from "../../../../../hooks";

type ShowGameScoresProps = {
  games: Game[];
} & Filters;

export const ShowGameScores = ({
  games,
  ...filters
}: ShowGameScoresProps): JSX.Element => {
  const [selectedGame, setSelectedGame] = useState(games[0].name || "");
  const { isMultiGameView } = useTypedSelector(
    ({ filterScores }) => filterScores
  );

  useEffect(() => {
    setSelectedGame(games[0].name || "");
  }, [games, setSelectedGame]);

  return (
    <>
      {!isMultiGameView && (
        <GameCardList
          selectedGame={selectedGame}
          onGameClick={setSelectedGame}
          games={games}
        />
      )}
      <GamesList
        games={
          isMultiGameView
            ? games
            : games.filter(({ name }) => selectedGame === name)
        }
        {...filters}
      />
    </>
  );
};
