import { useState } from "react";
import styles from "./BindedPlayers.module.scss";

import {
  getLocalStorageObject,
  setLocalStorageObject,
} from "../../../../utils";
import { BINDED_PLAYERS_LOCAL_STORAGE_KEY } from "../../constants";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useActions, useAnalyticsEventTracker } from "../../../../hooks";

export const BindedPlayers = (): JSX.Element | null => {
  const { eventTracker } = useAnalyticsEventTracker("bound players");
  const { fetchPlayerInformation } = useActions();
  const [bindedPlayers, setBindedPlayers] = useState(
    getLocalStorageObject(BINDED_PLAYERS_LOCAL_STORAGE_KEY)
  );

  if (!bindedPlayers) {
    return null;
  }

  const onValueChange = (key: string, value: string) => {
    setBindedPlayers({ ...bindedPlayers, [key]: value });
  };

  const saveBindedPlayersOnBlur = () => {
    eventTracker("Updated list of bound players");
    setLocalStorageObject(BINDED_PLAYERS_LOCAL_STORAGE_KEY, bindedPlayers);
  };

  const onGoButtonClick = (username: string) => () => {
    fetchPlayerInformation(username);
  };

  return (
    <div className={styles.wrapper}>
      <h1>Bound Players</h1>
      <div>
        {Object.entries(bindedPlayers).map(([key, value]) => (
          <div key={key}>
            <strong className={styles.label}>KEY</strong>
            <TextInput className={styles.keyInput} value={key} disabled />
            <strong className={styles.label}>VALUE</strong>
            <TextInput
              value={value}
              placeholder="Enter profile name"
              onChange={(e) => onValueChange(key, e.target.value)}
              onBlur={saveBindedPlayersOnBlur}
            />
            <button
              className={styles.goButton}
              onClick={onGoButtonClick(value)}
            >
              Go
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
