import styles from "./RemoveX.module.scss";
import { FiXCircle } from "react-icons/fi";

type RemoveXProps = {
  onClick: () => void;
};

export const RemoveX = ({ onClick }: RemoveXProps): JSX.Element => {
  return <FiXCircle className={styles.removeIcon} onClick={onClick} />;
};
