// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompareToYourselfTable_tableWrapper__lXEbp {\n  display: flex;\n  justify-content: center; }\n\n.CompareToYourselfTable_table__wXIaU {\n  text-align: right;\n  border-collapse: collapse;\n  margin-bottom: 24px; }\n  .CompareToYourselfTable_table__wXIaU tr,\n  .CompareToYourselfTable_table__wXIaU th,\n  .CompareToYourselfTable_table__wXIaU td {\n    border: 1px solid white; }\n  .CompareToYourselfTable_table__wXIaU th,\n  .CompareToYourselfTable_table__wXIaU td {\n    padding: 8px; }\n  .CompareToYourselfTable_table__wXIaU th {\n    text-align: center; }\n\n.CompareToYourselfTable_personalRecord__\\+mHPz {\n  color: lightgreen;\n  font-weight: 600; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/CompareToYourselfTable.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAA;;AAGzB;EACE,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB,EAAA;EAHrB;;;IAOI,uBAAuB,EAAA;EAP3B;;IAWI,YAAY,EAAA;EAXhB;IAcI,kBAAkB,EAAA;;AAItB;EACE,iBAAiB;EACjB,gBAAgB,EAAA","sourcesContent":[".tableWrapper {\n  display: flex;\n  justify-content: center;\n}\n\n.table {\n  text-align: right;\n  border-collapse: collapse;\n  margin-bottom: 24px;\n  tr,\n  th,\n  td {\n    border: 1px solid white;\n  }\n  th,\n  td {\n    padding: 8px;\n  }\n  th {\n    text-align: center;\n  }\n}\n\n.personalRecord {\n  color: lightgreen;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": "CompareToYourselfTable_tableWrapper__lXEbp",
	"table": "CompareToYourselfTable_table__wXIaU",
	"personalRecord": "CompareToYourselfTable_personalRecord__+mHPz"
};
export default ___CSS_LOADER_EXPORT___;
