import styles from "./CompareToYourselfTable.module.scss";
import { TotalScorePerGame } from "./TotalScorePerGame";
import {
  getKeyIdForTopScore,
  getScoreByGame,
  getTopScores,
  isPersonalRecord,
} from "../../../utils";
import { usePlayerInfo } from "../../../../../hooks";
import { Game } from "../../../../../redux/models";

type CompareToYourselfTableProps = {
  games: Game[];
};

const HARDCODED_COLUMNS = 2;

export const CompareToYourselfTable = ({
  games,
}: CompareToYourselfTableProps): JSX.Element => {
  const { playerProfiles } = usePlayerInfo();
  const topScores = getTopScores(playerProfiles);

  return (
    <>
      {games.map((game) => (
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th colSpan={HARDCODED_COLUMNS + playerProfiles.length}>
                  {game.name}
                </th>
              </tr>

              <tr>
                <th>Level</th>
                {playerProfiles.map((p) => (
                  <th key={`${p.player.local_rank}-${p.player.player_name}`}>
                    {p.location.name}
                  </th>
                ))}
                <th>Top Score</th>
              </tr>
            </thead>
            <tbody>
              {game.levels.map((level) => {
                let hasPersonalRecord = false;
                const topScoreKey = getKeyIdForTopScore({
                  level_id: level.level_id,
                  game_id: level.game_id,
                });

                return (
                  <tr key={`level-${level.id}`}>
                    <td>{level.level_id + 1}</td>
                    {playerProfiles.map(({ player }) => {
                      const score = getScoreByGame(
                        player,
                        level.game_id,
                        level.level_id
                      );

                      if (!hasPersonalRecord) {
                        hasPersonalRecord = isPersonalRecord(
                          score,
                          topScores[topScoreKey]
                        );
                      }

                      return (
                        <td
                          className={
                            (isPersonalRecord(score, topScores[topScoreKey]) &&
                              styles.personalRecord) ||
                            undefined
                          }
                        >
                          {score}
                        </td>
                      );
                    })}
                    <td
                      className={
                        (hasPersonalRecord && styles.personalRecord) ||
                        undefined
                      }
                    >
                      {topScores[topScoreKey] || 0}
                    </td>
                  </tr>
                );
              })}
              <TotalScorePerGame
                levels={game.levels}
                players={playerProfiles.map((pp) => pp.player)}
                forceShow
                topScoresAllLocations={topScores}
              />
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
};
