import produce from "immer";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { Player, PlayerInfo, LeaderboardPosition } from "../models";

export const emptyPlayer: Player = {
  high_scores: [],
  levels: 0,
  passedLevels: 0,
  local_rank: 0,
  player_name: "",
  player_rank: 0,
  stars: 0,
  total_score: 0,
  local_score: 0,
  topScores: {},
  notTopScores: {},
  missingLevels: {},
  starsOldRankingSystem: 0,
};

type State = {
  playerProfiles: PlayerInfo[];
  comparePlayers: Player[];
  errorMessage: string;
  leaderboard: LeaderboardPosition[];
  isLoading: boolean;
  isLoadingLeaderboard: boolean;
  isLoadingCompareAction: boolean;
  errorMessageLeaderboard: string;
  locations: string[];
  playerBeingSearched: string;
} & PlayerInfo;

const initialState: State = {
  location: {
    id: 0,
    levels_count: 0,
    name: "",
    rankings_count: 0,
    rooms: [],
  },
  player: emptyPlayer,
  playerProfiles: [],
  comparePlayers: [],
  errorMessage: "",
  leaderboard: [],
  isLoading: false,
  isLoadingLeaderboard: false,
  isLoadingCompareAction: false,
  errorMessageLeaderboard: "",
  locations: [],
  playerBeingSearched: "",
};

export const scoreReducer = produce((state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.LOADING_FETCH_PLAYER_INFO:
      state.isLoading = action.payload;
      return state;
    case ActionType.CLEAR_PLAYER_INFO:
      state.player = emptyPlayer;
      state.comparePlayers = [];
      return state;
    case ActionType.FETCH_PLAYER_INFO_COMPLETE:
      state.player = action.payload.player;
      state.location = action.payload.location;
      return state;
    case ActionType.IS_LOADING_COMPARE_ACTION:
      state.isLoadingCompareAction = action.payload;
      return state;
    case ActionType.FETCH_PLAYER_YESTERDAY_INFO_COMPLETE:
      state.playerYesterday = action.payload.player;
      return state;
    case ActionType.CLEAR_PLAYER_YESTERDAY_INFO:
      state.playerYesterday = undefined;
      return state;
    case ActionType.FETCH_LOCATIONS_COMPLETE:
      state.locations = action.payload;
      return state;
    case ActionType.CLEAR_LOCATIONS:
      state.locations = [];
      return state;
    case ActionType.SAVE_PLAYER_BEING_SEARCHED:
      state.playerBeingSearched = action.payload;
      return state;
    case ActionType.FETCH_LIST_PLAYERS:
      state.comparePlayers = action.payload;
      return state;
    case ActionType.ADD_NEW_COMPARED_PLAYERS:
      state.comparePlayers = [...state.comparePlayers, ...action.payload];
      return state;
    case ActionType.FETCH_PLAYER_ALL_LOCATIONS:
      state.playerProfiles = action.payload;
      return state;
    case ActionType.CLEAR_PLAYER_ALL_LOCATIONS:
      state.playerProfiles = [];
      return state;
    case ActionType.REMOVE_PLAYER_LIST_PLAYERS:
      state.comparePlayers = state.comparePlayers.filter(
        ({ player_name }) => player_name !== action.payload
      );
      return state;
    case ActionType.FETCH_PLAYER_INFO_ERROR:
      state.errorMessage = action.payload;
      return state;
    case ActionType.FETCH_PLAYER_YESTERDAY_INFO_ERROR:
      state.errorMessage = action.payload;
      return state;
    case ActionType.LOADING_FETCH_LEADERBOARD:
      state.isLoadingLeaderboard = action.payload;
      return state;
    case ActionType.FETCH_LEADERBOARD_COMPLETE:
      state.leaderboard = action.payload;
      return state;
    case ActionType.FETCH_LEADERBOARD_ERROR:
      state.errorMessageLeaderboard = action.payload;
      return state;
    default:
      return state;
  }
}, initialState);
