import styles from "./GameCardList.module.scss";

import { GameCard } from "./GameCard";
import { Game } from "../../../../../redux/models";

type GameCardListProps = {
  games: Game[];
  onGameClick: (name: string) => void;
  selectedGame: string;
};

export const GameCardList = ({
  games,
  onGameClick,
  selectedGame,
}: GameCardListProps): JSX.Element => {
  const gameNames = games.map(({ name }) => name);

  let remainingGames: string[] = [];
  if (gameNames.length > 4) {
    remainingGames = gameNames.splice(4);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.firstBatch}>
        {gameNames.map((name, i) => (
          <GameCard
            selectedGame={selectedGame}
            onGameClick={onGameClick}
            key={i}
            title={name}
          />
        ))}
      </div>
      <div className={styles.secondBatch}>
        {remainingGames.map((name, i) => (
          <GameCard
            selectedGame={selectedGame}
            onGameClick={onGameClick}
            key={i}
            title={name}
          />
        ))}
      </div>
    </div>
  );
};
