// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MiniTournament_wrapper__V1QfS {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: white; }\n\n.MiniTournament_scoreWrapper__SPMs\\+ {\n  margin: 8px 0; }\n\n.MiniTournament_label__UfLIU {\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  margin-right: 20px; }\n\n.MiniTournament_keyInput__DT8jf {\n  margin-right: 20px; }\n\n.MiniTournament_button__0ALiJ {\n  margin-top: 16px; }\n\n.MiniTournament_gameWrapper__QiR10 {\n  border: 1px solid #ffffff55;\n  margin-bottom: 8px;\n  padding: 8px; }\n\n.MiniTournament_buttonsWrapper__0J323 {\n  display: flex;\n  justify-content: space-between; }\n\n.MiniTournament_removeButton__G5BD9 {\n  display: flex;\n  justify-content: flex-end; }\n", "",{"version":3,"sources":["webpack://./src/pages/MiniTournament/MiniTournament.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY,EAAA;;AAGd;EACE,aAAa,EAAA;;AAGf;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB,EAAA;;AAGlB;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY,EAAA;;AAGd;EACE,aAAa;EACb,8BAA8B,EAAA;;AAGhC;EACE,aAAa;EACb,yBAAyB,EAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: white;\n}\n\n.scoreWrapper {\n  margin: 8px 0;\n}\n\n.label {\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  margin-right: 20px;\n}\n\n.keyInput {\n  margin-right: 20px;\n}\n\n.button {\n  margin-top: 16px;\n}\n\n.gameWrapper {\n  border: 1px solid #ffffff55;\n  margin-bottom: 8px;\n  padding: 8px;\n}\n\n.buttonsWrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\n.removeButton {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MiniTournament_wrapper__V1QfS",
	"scoreWrapper": "MiniTournament_scoreWrapper__SPMs+",
	"label": "MiniTournament_label__UfLIU",
	"keyInput": "MiniTournament_keyInput__DT8jf",
	"button": "MiniTournament_button__0ALiJ",
	"gameWrapper": "MiniTournament_gameWrapper__QiR10",
	"buttonsWrapper": "MiniTournament_buttonsWrapper__0J323",
	"removeButton": "MiniTournament_removeButton__G5BD9"
};
export default ___CSS_LOADER_EXPORT___;
