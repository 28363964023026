import { HighScore, Level, PlayerInfo } from "../../../redux/models";
import { Player } from "../../../redux/models/player";
import { TopScores } from "./types";

export const isPersonalRecord = (score: number, topScore?: number): boolean => {
  if (!topScore) {
    return false;
  }

  return score === topScore;
};

export const getScoreByGame = (
  player: Player | null,
  gameId: number,
  levelId: number
): number => {
  const gameLevel = getGameLevel(player, gameId, levelId);

  return gameLevel?.score || 0;
};

export const getTimesPassedLevel = (
  player: Player | null,
  gameId: number,
  levelId: number
): number => {
  const gameLevel = getGameLevel(player, gameId, levelId);

  return gameLevel?.times || 0;
};

const getGameLevel = (
  player: Player | null,
  gameId: number,
  levelId: number
): HighScore | undefined => {
  if (!player) {
    return undefined;
  }

  return player.high_scores.find(
    ({ game_id, level_id }) => game_id === gameId && level_id === levelId
  );
};

export const getKeyIdForTopScore = ({
  game_id,
  level_id,
}: Pick<Level, "game_id" | "level_id">): string => `${game_id}-${level_id}`;

export const getTopScores = (players: PlayerInfo[]): TopScores => {
  const topScores: TopScores = {};

  players.forEach(({ location }) => {
    location.rooms.forEach(({ games }) => {
      games.forEach(({ levels }) => {
        levels.forEach(({ top_score, level_id, game_id }) => {
          const key = getKeyIdForTopScore({ level_id, game_id });
          if (!topScores[key] || topScores[key] < top_score) {
            topScores[key] = top_score;
          }
        });
      });
    });
  });

  return topScores;
};
