// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Stars_wrapper__K8N5c {\n  display: flex;\n  align-items: flex-end; }\n\n.Stars_star__TpRXe {\n  font-size: 196px; }\n\n.Stars_text__pBysp {\n  font-size: 56px;\n  text-align: left; }\n\n.Stars_starsCount__Bgp8o {\n  font-size: 64px;\n  font-weight: 600; }\n\n.Stars_starsCountWrapper__\\+yFkA {\n  margin-left: 48px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Summary/components/Stars.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB,EAAA;;AAGvB;EACE,gBAAgB,EAAA;;AAGlB;EACE,eAAe;EACf,gBAAgB,EAAA;;AAGlB;EACE,eAAe;EACf,gBAAgB,EAAA;;AAGlB;EACE,iBAAiB,EAAA","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: flex-end;\n}\n\n.star {\n  font-size: 196px;\n}\n\n.text {\n  font-size: 56px;\n  text-align: left;\n}\n\n.starsCount {\n  font-size: 64px;\n  font-weight: 600;\n}\n\n.starsCountWrapper {\n  margin-left: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Stars_wrapper__K8N5c",
	"star": "Stars_star__TpRXe",
	"text": "Stars_text__pBysp",
	"starsCount": "Stars_starsCount__Bgp8o",
	"starsCountWrapper": "Stars_starsCountWrapper__+yFkA"
};
export default ___CSS_LOADER_EXPORT___;
