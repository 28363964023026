// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Link_link__j3yA7 {\n  padding: 8px;\n  margin: 8px;\n  border-radius: 4px;\n  background-color: #008060;\n  color: #ffffff;\n  text-decoration: none; }\n", "",{"version":3,"sources":["webpack://./src/components/Link/Link.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,qBAAqB,EAAA","sourcesContent":[".link {\n  padding: 8px;\n  margin: 8px;\n  border-radius: 4px;\n  background-color: #008060;\n  color: #ffffff;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Link_link__j3yA7"
};
export default ___CSS_LOADER_EXPORT___;
