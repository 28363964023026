// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banner_banner__C9vZp, .Banner_alert__DyyAv, .Banner_success__OQLh5 {\n  padding: 12px 0;\n  text-align: center;\n  font-size: 24px; }\n  .Banner_banner__C9vZp a, .Banner_alert__DyyAv a, .Banner_success__OQLh5 a {\n    color: black; }\n\n.Banner_alert__DyyAv {\n  background-color: red; }\n\n.Banner_success__OQLh5 {\n  background-color: greenyellow; }\n", "",{"version":3,"sources":["webpack://./src/components/Banner/Banner.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe,EAAA;EAHjB;IAMI,YAAY,EAAA;;AAIhB;EAEE,qBAAqB,EAAA;;AAGvB;EAEE,6BAA6B,EAAA","sourcesContent":[".banner {\n  padding: 12px 0;\n  text-align: center;\n  font-size: 24px;\n\n  a {\n    color: black;\n  }\n}\n\n.alert {\n  @extend .banner;\n  background-color: red;\n}\n\n.success {\n  @extend .banner;\n  background-color: greenyellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "Banner_banner__C9vZp",
	"alert": "Banner_alert__DyyAv",
	"success": "Banner_success__OQLh5"
};
export default ___CSS_LOADER_EXPORT___;
