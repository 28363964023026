import { Dispatch } from "react";

import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { activateAPI } from "../../api";
import {
  parseLeaderboard,
  compareLeaderboards,
  getBindedPlayer,
  parsePlayer,
  encodeString,
} from "../../utils";
import { Player } from "../models";
import { RootState } from "../reducers";
import { LeaderboardResponse } from "../responseTypes";

// triggers an api call just to log in the api, not actually doing anything
export const fakeAPICall = (url: string) => async (): Promise<void> => {
  try {
    await activateAPI.get(url);
  } catch (e) {
    // do nothing
  }
};

/**
 *
 * @param names Player Names divided by semi-colons
 * @param locationName location name
 */
export const fetchListPlayers = (names: string, locationName: string) => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: true,
    });
    // Modify this part to become clearListPlayers
    if (!names) {
      dispatch({ type: ActionType.FETCH_LIST_PLAYERS, payload: [] });
    } else {
      const playerNames = names
        .split(";")
        .filter(
          (name) =>
            name.toLowerCase() !==
            getState().playerInfo.player.player_name.toLowerCase()
        );
      const currentPlayers = getState().playerInfo.comparePlayers.map(
        (player) => player.player_name
      );

      const newListPlayers = [
        ...playerNames.filter(
          (playerName) =>
            !currentPlayers
              .map((p) => p.toLowerCase())
              .includes(playerName.toLowerCase())
        ),
      ];

      const players = await Promise.all(
        newListPlayers.map(async (playerName) => {
          const player = await activateAPI.get(
            `/data/locations/${locationName}/processed-compareplayers/${getBindedPlayer(
              playerName.trim()
            )}`
          );
          const parsedPlayer = parsePlayer(player.data);
          if (parsedPlayer) {
            return parsedPlayer.player;
          } else {
            return null;
          }
        })
      );

      const filteredPlayers = players.filter(
        (player) => player !== null
      ) as Player[];

      dispatch({
        type: ActionType.ADD_NEW_COMPARED_PLAYERS,
        payload: filteredPlayers,
      });
    }

    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: false,
    });
  };
};

export const removePlayerListPlayers =
  (name: string) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.REMOVE_PLAYER_LIST_PLAYERS, payload: name });
  };

export const fetchLeaderboard =
  (city: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.LOADING_FETCH_LEADERBOARD, payload: true });

    try {
      const leaderboard = await activateAPI.get<LeaderboardResponse>(
        `/data/processed-leaderboard/${city.trim()}`
      );

      const { today, yesterday } = leaderboard.data;

      if (today.city && yesterday.city) {
        const parsedToday = parseLeaderboard(today);
        const parsedYesterday = parseLeaderboard(yesterday);

        dispatch({
          type: ActionType.FETCH_LEADERBOARD_COMPLETE,
          payload: compareLeaderboards(parsedYesterday, parsedToday, city),
        });
      } else {
        dispatch({
          type: ActionType.FETCH_LEADERBOARD_COMPLETE,
          payload: [],
        });
      }
      dispatch({
        type: ActionType.FETCH_LEADERBOARD_ERROR,
        payload: "",
      });
    } catch (err) {
      dispatch({
        type: ActionType.FETCH_LEADERBOARD_ERROR,
        payload: "There was an error while trying to retrieve leaderboard.",
      });
    } finally {
      dispatch({ type: ActionType.LOADING_FETCH_LEADERBOARD, payload: false });
    }
  };

export const fetchPlayerInformation = (
  playerName: string,
  locationName?: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    const trimmedPlayerName = getBindedPlayer(playerName.trim());
    dispatch({ type: ActionType.LOADING_FETCH_PLAYER_INFO, payload: true });
    // not really comparing anything, just a visual spinner for the user when they are already checking their scores
    dispatch({ type: ActionType.IS_LOADING_COMPARE_ACTION, payload: true });
    if (!locationName) {
      try {
        // URL will change to /players in the future
        const locations = await activateAPI.get<string[]>(
          `/data/processed-playerlocations/${trimmedPlayerName}`
        );
        if (locations.data.length) {
          if (locations.data.length === 1) {
            await fetchPlayer(trimmedPlayerName, locations.data[0], dispatch);
          } else {
            dispatch({
              type: ActionType.FETCH_LOCATIONS_COMPLETE,
              payload: locations.data,
            });
            dispatch({
              type: ActionType.SAVE_PLAYER_BEING_SEARCHED,
              payload: playerName,
            });
          }
        }
      } catch (err) {
        dispatch({
          type: ActionType.FETCH_PLAYER_INFO_ERROR,
          payload: "No player was found.",
        });
      } finally {
        dispatch({
          type: ActionType.LOADING_FETCH_PLAYER_INFO,
          payload: false,
        });
      }
    } else {
      await fetchPlayer(trimmedPlayerName, locationName, dispatch);
    }
    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: false,
    });
  };
};

const fetchPlayer = async (
  playerName: string,
  locationName: string,
  dispatch: Dispatch<Action>
) => {
  dispatch({ type: ActionType.LOADING_FETCH_PLAYER_INFO, payload: true });

  try {
    const player = await activateAPI.get(
      `/data/locations/${locationName}/processed-players/${playerName}`
    );
    const parsedPlayer = parsePlayer(player.data);
    if (parsedPlayer) {
      dispatch({
        type: ActionType.FETCH_PLAYER_INFO_COMPLETE,
        payload: parsedPlayer,
      });
      dispatch({
        type: ActionType.FETCH_PLAYER_INFO_ERROR,
        payload: "",
      });
    }
  } catch (err) {
    dispatch({
      type: ActionType.FETCH_PLAYER_INFO_ERROR,
      payload: "No player was found.",
    });
  } finally {
    dispatch({ type: ActionType.LOADING_FETCH_PLAYER_INFO, payload: false });
  }
};

export const clearPlayerYesterday = () => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.CLEAR_PLAYER_YESTERDAY_INFO,
  });
};

export const fetchPlayerYesterday =
  () => async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: true,
    });
    const { playerInfo } = getState();
    const player = await activateAPI.get(
      `/locations/${playerInfo.location.name}/players/${encodeString(
        playerInfo.player.player_name
      )}/yesterday`
    );
    const parsedPlayer = parsePlayer(player.data);
    if (parsedPlayer) {
      dispatch({
        type: ActionType.FETCH_PLAYER_YESTERDAY_INFO_COMPLETE,
        payload: parsedPlayer,
      });
      dispatch({
        type: ActionType.FETCH_PLAYER_YESTERDAY_INFO_ERROR,
        payload: "",
      });
    }
    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: false,
    });
  };

export const fetchPlayerAllLocations =
  () => async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: true,
    });
    const { playerInfo } = getState();
    const profilesResponse = await activateAPI.get(
      `/data/locations/allLocations/processed-players/${encodeString(
        playerInfo.player.player_name
      )}`
    );

    const profiles = profilesResponse.data;

    dispatch({
      type: ActionType.FETCH_PLAYER_ALL_LOCATIONS,
      payload: profiles.map((p: any) => parsePlayer(p)),
    });

    dispatch({
      type: ActionType.IS_LOADING_COMPARE_ACTION,
      payload: false,
    });
  };

export const clearPlayerAllLocations = () => (dispatch: Dispatch<Action>) =>
  dispatch({ type: ActionType.CLEAR_PLAYER_ALL_LOCATIONS });

export const clearPreviousSearch = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_LOCATIONS,
    });
    dispatch({ type: ActionType.CLEAR_PLAYER_INFO });
  };
};
