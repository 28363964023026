import { getScoreByGame } from "../pages/Activate/utils";
import { Location, Player } from "../redux/models";
import { getLocalStorageItem, setLocalStorageItem } from "./localStorage";

type GetMonthlyScoreProps = {
  player: Player;
  location: Location;
};

type SetMonthlyScoreProps = {
  player: Player;
  location: Location;
  gameId: number;
  levelId: number;
};

type IsGameLevelOnMonthlyScore = SetMonthlyScoreProps;

// local storage must save string with gameId;levelId separated by comma
// i.e. 1;1200,2;2300,...
export const getMonthlyScore = ({
  player,
  location,
}: GetMonthlyScoreProps): number => {
  const LOCAL_STORAGE_KEY_MONTHLY_SCORE = `monthly-score_${location.name}_${player.player_name}`;
  const monthlyScore = getLocalStorageItem(LOCAL_STORAGE_KEY_MONTHLY_SCORE);

  if (monthlyScore) {
    const gamesList = monthlyScore.split(",");
    return gamesList.reduce((acc, currentGame) => {
      const [gameId, levelId] = currentGame.split(";").map<number>(Number);
      const gameScore = getScoreByGame(player, gameId, levelId);

      return acc + gameScore;
    }, 0);
  }

  return 0;
};

export const isGameLevelOnMonthlyScore = ({
  player,
  location,
  gameId,
  levelId,
}: IsGameLevelOnMonthlyScore): boolean => {
  const LOCAL_STORAGE_KEY_MONTHLY_SCORE = `monthly-score_${location.name}_${player.player_name}`;
  const monthlyScore = getLocalStorageItem(LOCAL_STORAGE_KEY_MONTHLY_SCORE);
  if (monthlyScore) {
    return monthlyScore.split(",").indexOf(`${gameId};${levelId}`) !== -1;
  }

  return false;
};

export const resetMonthlyScore = ({
  player,
  location,
}: GetMonthlyScoreProps): void => {
  const LOCAL_STORAGE_KEY_MONTHLY_SCORE = `monthly-score_${location.name}_${player.player_name}`;
  setLocalStorageItem(LOCAL_STORAGE_KEY_MONTHLY_SCORE, "");
};

export const setMonthlyScore = ({
  player,
  location,
  gameId,
  levelId,
}: SetMonthlyScoreProps): void => {
  const target = `${gameId};${levelId}`;

  const LOCAL_STORAGE_KEY_MONTHLY_SCORE = `monthly-score_${location.name}_${player.player_name}`;
  const monthlyScore = getLocalStorageItem(LOCAL_STORAGE_KEY_MONTHLY_SCORE);
  if (monthlyScore) {
    const monthlyScoreArray = monthlyScore.split(",");

    // Check if the target gameId + levelId exists in the array
    const index = monthlyScoreArray.indexOf(target);

    if (index !== -1) {
      monthlyScoreArray.splice(index, 1);
    } else {
      monthlyScoreArray.push(target);
    }

    // save to local storage
    setLocalStorageItem(
      LOCAL_STORAGE_KEY_MONTHLY_SCORE,
      monthlyScoreArray.join(",")
    );

    return;
  }

  // if doesn't exist yet, sets for the first time
  setLocalStorageItem(LOCAL_STORAGE_KEY_MONTHLY_SCORE, target);
};
