import React from "react";
import styles from "./LevelRows.module.scss";

import { ScoreDifference } from "./ScoreDifference";
import {
  getKeyIdForTopScore,
  getScoreByGame,
  isPersonalRecord,
} from "../../../utils";
import {
  useOtherLocations,
  usePlayerInfo,
  useTypedSelector,
} from "../../../../../hooks/";
import { Level, Player } from "../../../../../redux/models";
import { TopScores } from "../../../utils/types";

type TotalScorePerGameProps = {
  levels: Pick<Level, "top_score" | "game_id" | "level_id">[];
  players: Player[];
  forceShow?: boolean;
  topScoresAllLocations?: TopScores;
};

export const TotalScorePerGame = ({
  levels,
  players,
  forceShow = false,
  topScoresAllLocations,
}: TotalScorePerGameProps): JSX.Element => {
  const { isComparingWithOtherLocations } = useOtherLocations();
  const { showTotalScorePerGame, showScoreDifference } = useTypedSelector(
    ({ filterScores }) => filterScores
  );
  const { comparePlayers } = usePlayerInfo();

  if (!showTotalScorePerGame && !forceShow) {
    return <React.Fragment />;
  }

  const totalScoresPerPlayer = Array.from({ length: players.length }, () => 0);
  players.forEach((player, i) => {
    const totalScores = levels.reduce((score, currenLevel) => {
      const scoreByGame = getScoreByGame(
        player,
        currenLevel.game_id,
        currenLevel.level_id
      );

      return score + scoreByGame;
    }, 0);

    totalScoresPerPlayer[i] = totalScores;
  });

  const totalTopScores = levels.reduce(
    (acc, { game_id, level_id, top_score }) => {
      const key = getKeyIdForTopScore({ game_id, level_id });
      return (
        acc + (topScoresAllLocations ? topScoresAllLocations[key] : top_score)
      );
    },
    0
  );
  let hasPersonalRecord = false;

  const isComparing =
    isComparingWithOtherLocations || comparePlayers.length > 0;

  return (
    <tr>
      <th colSpan={isComparing ? 1 : 2}>Total</th>
      {totalScoresPerPlayer.map((score, i) => {
        if (!hasPersonalRecord && totalTopScores) {
          hasPersonalRecord = isPersonalRecord(score, totalTopScores);
        }
        return (
          <td
            key={i}
            className={
              (score &&
                isPersonalRecord(score, totalTopScores) &&
                styles.personalRecord) ||
              undefined
            }
          >
            {score}
            <ScoreDifference
              score={score}
              topScore={totalTopScores}
              visible={showScoreDifference}
            />
          </td>
        );
      })}
      <td className={(hasPersonalRecord && styles.personalRecord) || undefined}>
        {totalTopScores}
      </td>
    </tr>
  );
};
