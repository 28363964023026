import { useEffect, useState, useCallback } from "react";

import styles from "./Leaderboard.module.scss";

import { Checkbox } from "../../../../components";
import { useActions, useTypedSelector } from "../../../../hooks/";
import { PositionDifference } from "./components/PositionDifference";
import { LeaderboardScoreDifference } from "./components/LeaderboardScoreDifference";
import classNames from "classnames";

export const Leaderboard = (): JSX.Element => {
  const { fetchLeaderboard } = useActions();

  const [showScoreDifferenceToTopPlayer, setShowScoreDifferenceToTopPlayer] =
    useState(false);
  const {
    player: { player_name },
    leaderboard,
    isLoadingLeaderboard,
    errorMessageLeaderboard,
    location: { name: city },
  } = useTypedSelector(({ playerInfo }) => playerInfo);

  useEffect(() => {
    fetchLeaderboard(city);
  }, [fetchLeaderboard, city]);

  const onShowScoreDifferenceToTopPlayerClick = useCallback(() => {
    setShowScoreDifferenceToTopPlayer(!showScoreDifferenceToTopPlayer);
  }, [showScoreDifferenceToTopPlayer]);

  return (
    <div>
      <div className={styles.optionsWrapper}>
        <Checkbox
          value={showScoreDifferenceToTopPlayer}
          onValueChange={onShowScoreDifferenceToTopPlayerClick}
          label="Show score difference to Top 1 Player"
          data-trackid="show score difference on leaderboard"
        />
      </div>
      <h1>{city} Leaderboard</h1>
      <div className={styles.tableWrapper}>
        {isLoadingLeaderboard ? (
          <p className={styles.loading}>Calculating Ranks</p>
        ) : errorMessageLeaderboard ? (
          <p className={styles.error}>{errorMessageLeaderboard}</p>
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Rank</th>
                <th className={styles.leftAlign}>Name</th>
                <th className={styles.leftAlign}>Score</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map(
                (
                  {
                    players,
                    rank,
                    local_score,
                    positionDifference,
                    scoreDifference,
                    scoreDifferenceFromTopPlayerScore,
                  },
                  i
                ) => {
                  const rowStyle = classNames(styles.row, {
                    [styles.currentUser]: (players as string) === player_name,
                  });
                  return (
                    <tr key={i} className={rowStyle}>
                      <td className={styles.centerAlign}>{rank}</td>
                      <td className={styles.differenceWrapper}>
                        {players as string}
                        <PositionDifference
                          positionDifference={positionDifference}
                        />
                      </td>
                      <td className={styles.leftAlign}>
                        {local_score}
                        <LeaderboardScoreDifference
                          scoreDifference={
                            showScoreDifferenceToTopPlayer
                              ? scoreDifferenceFromTopPlayerScore
                              : scoreDifference
                          }
                        />
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
