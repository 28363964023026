import styles from "./UpArrow.module.scss";
import { BsCaretUpFill } from "react-icons/bs";

type UpArrowProps = {
  positionDifference: number;
};

export const UpArrow = ({ positionDifference }: UpArrowProps): JSX.Element => {
  return (
    <>
      {positionDifference}
      <BsCaretUpFill className={styles.upArrow} />
    </>
  );
};
