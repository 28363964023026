// import thunk from 'redux-thunk';
import { configureStore } from "@reduxjs/toolkit";
// import { persistMiddleware } from './middlewares/persistMiddleware';
import { reducers } from "./reducers";

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
// export const store = configureStore({reducer: reducers, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)});
