import { combineReducers } from "redux";
import { albumsReducer } from "./albumReducer";
import { badgeReducer } from "./badgeReducer";
import { filterScoreReducer } from "./filterScoreReducer";
import { lastUpdatedReducer } from "./lastUpdatedReducer";
import { scoreReducer } from "./scoreReducer";
import { strategyReducer } from "./strategyReducer";

export const reducers = combineReducers({
  albums: albumsReducer,
  playerInfo: scoreReducer,
  filterScores: filterScoreReducer,
  strategy: strategyReducer,
  lastUpdated: lastUpdatedReducer,
  badge: badgeReducer,
});

export type RootState = ReturnType<typeof reducers>;
