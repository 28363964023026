import produce from "immer";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { Badge } from "../responseTypes";

type Sort = "ASC" | "DESC";

type State = {
  isLoading: boolean;
  badges: Badge[];
  sortByTitles?: Sort;
  sortByStars?: Sort;
};

const initialState: State = {
  isLoading: false,
  badges: [],
};

const SORT = {
  ASC: [1, -1],
  DESC: [-1, 1],
};

export const badgeReducer = produce((state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FETCH_BADGES_LOADING:
      state.isLoading = action.payload;
      return state;
    case ActionType.FETCH_BADGES_COMPLETE:
      state.badges = action.payload;

      return state;
    case ActionType.SORT_BADGES_BY_TITLES:
      switch (state.sortByTitles) {
        case "ASC":
          state.sortByTitles = "DESC";
          break;
        case "DESC":
          state.sortByTitles = "ASC";
          break;
        default:
          state.sortByTitles = "ASC";
          state.sortByStars = undefined;

          break;
      }
      const [sortATitle, sortBTitle] = SORT[state.sortByTitles];
      state.badges = state.badges.sort((a, b) =>
        a.title > b.title ? sortATitle : sortBTitle
      );

      return state;
    case ActionType.SORT_BADGES_BY_STARS:
      switch (state.sortByStars) {
        case "ASC":
          state.sortByStars = "DESC";
          break;
        case "DESC":
          state.sortByStars = "ASC";
          break;
        default:
          state.sortByStars = "ASC";
          state.sortByTitles = undefined;

          break;
      }
      const [sortAStars, sortBStars] = SORT[state.sortByStars];
      state.badges = state.badges.sort((a, b) =>
        a.stars > b.stars ? sortAStars : sortBStars
      );

      return state;
    default:
      return state;
  }
}, initialState);
