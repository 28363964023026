// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListPlayersToRemove_list__ZRFQ8 {\n  list-style-type: none;\n  padding: 0;\n  margin: 8px 0;\n  text-align: left;\n  font-size: 20px;\n  display: flex;\n  flex-direction: column; }\n  .ListPlayersToRemove_list__ZRFQ8 li {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n    cursor: pointer; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/ListPlayersToRemove.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,sBAAsB,EAAA;EAPxB;IASI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,eAAe,EAAA","sourcesContent":[".list {\n  list-style-type: none;\n  padding: 0;\n  margin: 8px 0;\n  text-align: left;\n  font-size: 20px;\n  display: flex;\n  flex-direction: column;\n  li {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ListPlayersToRemove_list__ZRFQ8"
};
export default ___CSS_LOADER_EXPORT___;
