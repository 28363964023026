// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LevelRows_personalRecord__4Q-RY {\n  color: lightgreen;\n  font-weight: 600; }\n\n.LevelRows_monthlyScore__-o0KN {\n  background-color: magenta; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/LevelRows.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB,EAAA;;AAGlB;EACE,yBAAyB,EAAA","sourcesContent":[".personalRecord {\n  color: lightgreen;\n  font-weight: 600;\n}\n\n.monthlyScore {\n  background-color: magenta;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personalRecord": "LevelRows_personalRecord__4Q-RY",
	"monthlyScore": "LevelRows_monthlyScore__-o0KN"
};
export default ___CSS_LOADER_EXPORT___;
