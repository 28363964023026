// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButton_button__0Ut99 {\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  color: white;\n  border: none;\n  font-size: 16px;\n  margin-bottom: 24px;\n  cursor: pointer;\n  padding: 4px 8px;\n  border-radius: 4px; }\n  .BackButton_button__0Ut99:hover {\n    background-color: #ffffff22; }\n\n.BackButton_text__UR0md {\n  margin-left: 4px;\n  margin-right: 4px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/BackButton/BackButton.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,eAAe;EAIf,gBAAgB;EAChB,kBAAkB,EAAA;EAbpB;IAUI,2BAA2B,EAAA;;AAM/B;EACE,gBAAgB;EAChB,iBAAiB,EAAA","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  color: white;\n  border: none;\n  font-size: 16px;\n  margin-bottom: 24px;\n  cursor: pointer;\n  &:hover {\n    background-color: #ffffff22;\n  }\n  padding: 4px 8px;\n  border-radius: 4px;\n}\n\n.text {\n  margin-left: 4px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "BackButton_button__0Ut99",
	"text": "BackButton_text__UR0md"
};
export default ___CSS_LOADER_EXPORT___;
