// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LeaderboardScoreDifference_scoreDifference__YMKKN {\n  margin-left: 8px;\n  font-size: 20px;\n  font-weight: 400; }\n\n.LeaderboardScoreDifference_positive__zpQl4 {\n  color: green; }\n\n.LeaderboardScoreDifference_negative__stiPI {\n  color: red; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Leaderboard/components/LeaderboardScoreDifference.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB,EAAA;;AAGlB;EACE,YAAY,EAAA;;AAGd;EACE,UAAU,EAAA","sourcesContent":[".scoreDifference {\n  margin-left: 8px;\n  font-size: 20px;\n  font-weight: 400;\n}\n\n.positive {\n  color: green;\n}\n\n.negative {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreDifference": "LeaderboardScoreDifference_scoreDifference__YMKKN",
	"positive": "LeaderboardScoreDifference_positive__zpQl4",
	"negative": "LeaderboardScoreDifference_negative__stiPI"
};
export default ___CSS_LOADER_EXPORT___;
