// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SamePosition_samePosition__d9trx {\n  margin-left: 4px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Leaderboard/components/SamePosition.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA","sourcesContent":[".samePosition {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"samePosition": "SamePosition_samePosition__d9trx"
};
export default ___CSS_LOADER_EXPORT___;
