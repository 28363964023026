import produce from "immer";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { RadioItemValue } from "../../components/";

type State = {
  showOnlyTopScores: boolean;
  showOnlyNotTopScores: boolean;
  showOnlyDifferentLevels: boolean;
  showOnlyLevelsToMatchScore: boolean;
  showOnlyLevelsToImprove: boolean;
  showOnlyMissingLevels: boolean;
  showScoreDifference: boolean;
  isMultiGameView: boolean;
  showTotalScorePerGame: boolean;
  improveScoreBy: RadioItemValue;
};

const initialState: State = {
  improveScoreBy: 200,
  showOnlyDifferentLevels: false,
  showOnlyLevelsToImprove: false,
  showOnlyLevelsToMatchScore: false,
  showOnlyMissingLevels: false,
  showOnlyNotTopScores: false,
  showOnlyTopScores: false,
  isMultiGameView: false,
  showScoreDifference: false,
  showTotalScorePerGame: false,
};

// Only toggle value if payload is undefined
const toggleValue = (toggleValue: boolean, payload?: boolean) => {
  return payload !== undefined ? payload : toggleValue;
};

export const filterScoreReducer = produce(
  (state: State, action: Action): State => {
    switch (action.type) {
      case ActionType.TOGGLE_SHOW_ONLY_DIFFERENT_LEVELS:
        state.showOnlyDifferentLevels = toggleValue(
          !state.showOnlyDifferentLevels,
          action.payload
        );
        return state;
      case ActionType.TOGGLE_SHOW_ONLY_LEVELS_TO_IMPROVE:
        state.showOnlyLevelsToImprove = toggleValue(
          !state.showOnlyLevelsToImprove,
          action.payload
        );
        return state;
      case ActionType.TOGGLE_SHOW_ONLY_LEVELS_TO_MATCH_SCORE:
        state.showOnlyLevelsToMatchScore = toggleValue(
          !state.showOnlyLevelsToMatchScore,
          action.payload
        );

        return state;
      case ActionType.TOGGLE_SHOW_ONLY_MISSING_LEVELS:
        state.showOnlyMissingLevels = toggleValue(
          !state.showOnlyMissingLevels,
          action.payload
        );
        return state;
      case ActionType.TOGGLE_SHOW_ONLY_NOT_TOP_SCORES:
        state.showOnlyNotTopScores = toggleValue(
          !state.showOnlyNotTopScores,
          action.payload
        );
        return state;
      case ActionType.TOGGLE_SHOW_ONLY_TOP_SCORES:
        state.showOnlyTopScores = toggleValue(
          !state.showOnlyTopScores,
          action.payload
        );
        return state;
      case ActionType.TOGGLE_SHOW_TOTAL_SCORE_PER_GAME:
        state.showTotalScorePerGame = !state.showTotalScorePerGame;
        return state;
      case ActionType.TOGGLE_SHOW_SCORE_DIFFERENCE_GAME_LEVEL:
        state.showScoreDifference = !state.showScoreDifference;
        return state;
      case ActionType.TOGGLE_MULTI_GAME_VIEW:
        state.isMultiGameView = !state.isMultiGameView;
        return state;
      default:
        return state;
    }
  },
  initialState
);
