/**
 * This function is neccessary on a try/catch basis due to the fact that
 * having localStorage being denied by the user won't just return an undefined,
 * it will thrown an error which makes the whole app unusable.
 * http://crocodillon.com/blog/always-catch-localstorage-security-and-quota-exceeded-errors
 */
export const getLocalStorage = (): Storage | null => {
  try {
    return window.localStorage;
  } catch (e) {
    return null;
  }
};

export const getLocalStorageItem = (item: string): string | null => {
  const storage = getLocalStorage();
  const itemLocalStorage = storage && storage.getItem(item);

  return itemLocalStorage ? itemLocalStorage : null;
};

export const setLocalStorageItem = (key: string, value: string) => {
  const storage = getLocalStorage();
  if (storage) {
    try {
      storage.setItem(key, value);
    } catch (e) {
      storage.clear();
    }
  }
};

export const setLocalStorageObject = (
  key: string,
  value: { [key: string]: string }
) => {
  const storage = getLocalStorage();
  if (storage) {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      storage.clear();
    }
  }
};

export const getLocalStorageObject = (
  item: string
): { [key: string]: string } | null => {
  const itemLocalStorage = getLocalStorageItem(item);

  return itemLocalStorage ? JSON.parse(itemLocalStorage) : null;
};
