// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PositionDifference_position__NpkLt {\n  display: flex;\n  padding-right: 16px;\n  font-size: 16px;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Leaderboard/components/PositionDifference.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB,EAAA","sourcesContent":[".position {\n  display: flex;\n  padding-right: 16px;\n  font-size: 16px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position": "PositionDifference_position__NpkLt"
};
export default ___CSS_LOADER_EXPORT___;
