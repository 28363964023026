export const generateGameKey = (): string => {
  return new Date().valueOf().toString();
};

export const GAMES_MINI_TOURNAMENT_LOCAL_STORAGE_KEY = "games-mini-tournament";
export const MINI_TOURNAMENT_ACTIONS_LOCAL_STORAGE_KEY =
  "mini-tournament-actions";

export const INITIAL_GAMES_MINI_TOURNAMENT: Record<string, string> = {
  [`${generateGameKey()}1`]: "Technique 3;;",
  [`${generateGameKey()}2`]: "Bop 5;;",
  [`${generateGameKey()}3`]: "Words 3;;",
  [`${generateGameKey()}4`]: "Barrage 4;;",
  [`${generateGameKey()}5`]: "Maze 4 or Zap 5;;",
  [`${generateGameKey()}6`]: "Mega Grid 2;;",
  [`${generateGameKey()}7`]: "Link 3 or Match 3;;",
  [`${generateGameKey()}8`]: "Asteroids 3 or Swat 3;;",
};

export const INITIAL_MINI_TOURNAMENT_ACTIONS: Record<string, string> = {
  startedPlaying: "",
  finished: "",
};
