// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingDiv_wrapper__-\\+1d8 {\n  min-height: 600px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  color: white; }\n\n.LoadingDiv_spinner__GJ39b {\n  border: 4px solid rgba(255, 255, 255, 0.3);\n  border-radius: 50%;\n  border-top: 4px solid #ffffff;\n  width: 30px;\n  height: 30px;\n  animation: LoadingDiv_spin__Fwnie 1s linear infinite;\n  margin-bottom: 16px; }\n\n@keyframes LoadingDiv_spin__Fwnie {\n  0% {\n    transform: rotate(0deg); }\n  100% {\n    transform: rotate(360deg); } }\n\n.LoadingDiv_placeholder__gi0Cf {\n  font-size: 18px; }\n", "",{"version":3,"sources":["webpack://./src/components/LoadingDiv/LoadingDiv.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY,EAAA;;AAGd;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,oDAAkC;EAClC,mBAAmB,EAAA;;AAGrB;EACE;IAAK,uBAAuB,EAAA;EAC5B;IAAO,yBAAyB,EAAA,EAAA;;AAGlC;EACE,eAAe,EAAA","sourcesContent":[".wrapper {\n  min-height: 600px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  color: white;\n}\n\n.spinner {\n  border: 4px solid rgba(255, 255, 255, 0.3);\n  border-radius: 50%;\n  border-top: 4px solid #ffffff;\n  width: 30px;\n  height: 30px;\n  animation: spin 1s linear infinite;\n  margin-bottom: 16px;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.placeholder {\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LoadingDiv_wrapper__-+1d8",
	"spinner": "LoadingDiv_spinner__GJ39b",
	"spin": "LoadingDiv_spin__Fwnie",
	"placeholder": "LoadingDiv_placeholder__gi0Cf"
};
export default ___CSS_LOADER_EXPORT___;
