import produce from 'immer';
import { Action } from '../actions';
import { ActionType } from '../actionTypes';

type Album = {
  id: number;
  userId: number;
  title: string;
}

const initialState: Album[] = [];

export const albumsReducer = produce((state: Album[], action: Action): Album[] => {
  switch(action.type) {
    case ActionType.ADD_ALBUM:
      state.push(action.payload);
      return state;
    case ActionType.FETCH_ALBUMS_COMPLETE:
      state = action.payload;
      return state;
    default:
      return state;
  }
}, initialState);