import { INACTIVE_GAME_ID } from "../pages/Activate/constants";
import { getScoreByGame } from "../pages/Activate/utils";
import { Location, Player } from "../redux/models";

const formatNumber = (n: number) => new Intl.NumberFormat().format(n);
const TODAY = new Date();

export const exportPlayer = (
  player: Player,
  { rooms, levels_count }: Location
) => {
  if (!rooms) {
    return "";
  }

  const data = [
    [
      `Activate: ${player.player_name}`,
      `${TODAY.getDate()}/${TODAY.getMonth()}/${TODAY.getFullYear()}`,
    ],
    ["Level", ...Array.from({ length: 10 }, (_, i) => `${i + 1}`), "Total"],
  ];

  rooms
    .filter(({ id }) => id !== INACTIVE_GAME_ID)
    .forEach(({ games, name }) => {
      let totalScorePerRoom = 0;
      data.push([name]);
      games.forEach((game) => {
        const gameData: string[] = [];
        let totalPerGame = 0;
        gameData.push(game.name);
        game.levels.forEach((level) => {
          const score = getScoreByGame(player, level.game_id, level.level_id);
          gameData.push(`${score}`);
          totalPerGame += score;
        });
        gameData.push(`${formatNumber(totalPerGame)}`);
        totalScorePerRoom += totalPerGame;
        data.push(gameData);
      });
      data.push([formatNumber(totalScorePerRoom).toString()]);
    });

  data.push([
    `Stars: ${player.stars}`,
    `Total Games: ${player.levels}/${levels_count}`,
    `TOTAL: ${formatNumber(player.total_score)}`,
  ]);

  return data;
};
