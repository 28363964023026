import styles from "./Filters.module.scss";
import { useState } from "react";
import { FaFilter } from "react-icons/fa";
import Modal from "react-modal";

import {
  useActions,
  useCompareOldScores,
  useOtherLocations,
  useTypedSelector,
} from "../../../../hooks";
import {
  RadioItemValue,
  Checkbox,
  RadioGroup,
  RadioItem,
} from "../../../../components";

const IMPROVE_LEVELS_BY_DEFAULT_ITEMS: RadioItem[] = [
  {
    label: "200 or more",
    value: 200,
  },
  {
    label: "500 or more",
    value: 500,
  },
];

type FiltersProps = {
  improveScoreBy: RadioItemValue;
  handleImproveScoreBy: (value: RadioItemValue) => void;
};

export const Filters = ({
  handleImproveScoreBy,
  improveScoreBy,
}: FiltersProps): JSX.Element | null => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    toggleShowOnlyDifferentLevels,
    toggleShowOnlyLevelsToMatchScore,
    toggleShowOnlyLevelsToImprove,
    toggleShowOnlyMissingLevels,
    toggleShowOnlyNotTopScores,
    toggleShowOnlyTopScores,
    toggleMultiGameView,
    toggleShowScoreDifferenceGameLevel,
    toggleShowTotalScorePerGame,
  } = useActions();

  const {
    playerInfo: { comparePlayers },
    filterScores: {
      showOnlyDifferentLevels,
      showOnlyLevelsToMatchScore,
      showOnlyLevelsToImprove,
      showOnlyMissingLevels,
      showOnlyNotTopScores,
      showOnlyTopScores,
      isMultiGameView,
      showScoreDifference,
      showTotalScorePerGame,
    },
  } = useTypedSelector(({ playerInfo, filterScores }) => ({
    playerInfo,
    filterScores,
  }));

  const { isComparingWithOtherLocations } = useOtherLocations();
  const { isComparingOldScores } = useCompareOldScores();

  Modal.setAppElement("#root");

  return (
    <>
      <p className={styles.labelWrapper} onClick={() => setIsModalOpen(true)}>
        <FaFilter /> <span className={styles.label}>Filters</span>
      </p>
      <Modal
        contentLabel="Filters"
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => setIsModalOpen(false)}
        className={styles.modal}
      >
        <div className={styles.optionsWrapper}>
          {isComparingWithOtherLocations || isComparingOldScores ? (
            <>
              <Checkbox
                value={isMultiGameView}
                onValueChange={toggleMultiGameView}
                label="Multi-Game View"
                data-trackid="multi-game view"
              />
            </>
          ) : (
            <>
              <Checkbox
                value={showScoreDifference}
                onValueChange={toggleShowScoreDifferenceGameLevel}
                label="Show difference from top score"
                data-trackid="show score difference"
              />
              <Checkbox
                value={showTotalScorePerGame}
                onValueChange={toggleShowTotalScorePerGame}
                label="Show total score per game"
                data-trackid="show total score per game"
              />
              {Boolean(comparePlayers.length) ? (
                <>
                  <Checkbox
                    value={showOnlyDifferentLevels}
                    onValueChange={toggleShowOnlyDifferentLevels}
                    label="Show only different levels"
                    data-trackid="filter by different levels"
                  />
                  <Checkbox
                    value={showOnlyLevelsToMatchScore}
                    label="Show only levels to match score"
                    onValueChange={toggleShowOnlyLevelsToMatchScore}
                    data-trackid="filter by levels to match score"
                  />
                </>
              ) : (
                <div>
                  <Checkbox
                    value={showOnlyTopScores}
                    onValueChange={toggleShowOnlyTopScores}
                    label="Show only top scores"
                    data-trackid="filter by top scores"
                  />
                  <Checkbox
                    value={showOnlyNotTopScores}
                    onValueChange={toggleShowOnlyNotTopScores}
                    label="Show only NOT top scores"
                    data-trackid="filter by not top scores"
                  />
                  <Checkbox
                    value={showOnlyMissingLevels}
                    onValueChange={toggleShowOnlyMissingLevels}
                    label="Show only missing levels"
                    data-trackid="filter by missing levels"
                  />
                  <div className={styles.levelsToImproveWrapper}>
                    <Checkbox
                      value={showOnlyLevelsToImprove}
                      onValueChange={toggleShowOnlyLevelsToImprove}
                      label="Show only levels to improve score"
                      data-trackid="filter by levels to improve score"
                    />
                    {showOnlyLevelsToImprove && (
                      <RadioGroup
                        className={styles.improveRadio}
                        name="improveScore"
                        onChange={handleImproveScoreBy}
                        value={improveScoreBy}
                        radioItems={IMPROVE_LEVELS_BY_DEFAULT_ITEMS}
                      />
                    )}
                  </div>
                </div>
              )}
              <Checkbox
                value={isMultiGameView}
                onValueChange={toggleMultiGameView}
                label="Multi-Game View"
                data-trackid="multi-game view"
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
