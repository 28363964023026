import { useState } from "react";

import styles from "./SideMenu.module.scss";
import { INACTIVE_GAME_ID } from "../../constants";
import { ChooseLocationModal } from "../../components/ChooseLocationModal/ChooseLocationModal";
import {
  useActions,
  useAnalyticsEventTracker,
  useCompareOldScores,
  useOtherLocations,
  useTypedSelector,
} from "../../../../hooks";

export type MenuActive =
  | "Account Summary"
  | "Games"
  | "Leaderboards"
  | "World Leaderboard"
  | "Badges";

export type RoomNameAndId = {
  roomId?: number;
  roomName: string;
};

type SideMenuProps = {
  menuActive: MenuActive;
  setMenuActive: React.Dispatch<React.SetStateAction<MenuActive>>;
  room: RoomNameAndId;
  onRoomClick: (room: RoomNameAndId) => void;
};

export const SideMenu = ({
  menuActive,
  setMenuActive,
  room,
  onRoomClick,
}: SideMenuProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    playerInfo: {
      locations,
      location,
      comparePlayers,
      player: { topScores, notTopScores, missingLevels },
    },
    filterScores: {
      showOnlyTopScores,
      showOnlyNotTopScores,
      showOnlyMissingLevels,
    },
  } = useTypedSelector(({ playerInfo, filterScores }) => ({
    playerInfo,
    filterScores,
  }));
  const { eventTracker } = useAnalyticsEventTracker("SideMenu");
  const { isComparingWithOtherLocations } = useOtherLocations();
  const { isComparingOldScores } = useCompareOldScores();
  const {
    clearPlayerAllLocations,
    fetchPlayerAllLocations,
    fetchPlayerYesterday,
    clearPlayerYesterday,
  } = useActions();

  const isRoomSelected = (id: number): string => {
    if (room.roomId === id) {
      return styles.active;
    }

    return "";
  };

  const isMenuActive = (menuOption: MenuActive): boolean => {
    return menuActive === menuOption;
  };

  const onMenuChange = (menu: MenuActive) => {
    eventTracker(`menu ${menu} selected`);
    setMenuActive(menu);
  };

  const renderFilterCountPerRoom = (id: number): string | null => {
    if (!comparePlayers.length) {
      let filterCount: string | null = null;
      if (showOnlyTopScores) {
        filterCount = (topScores[id] && `(${topScores[id]})`) || null;
      } else if (showOnlyNotTopScores) {
        filterCount = (notTopScores[id] && `(${notTopScores[id]})`) || null;
      } else if (showOnlyMissingLevels) {
        filterCount = (missingLevels[id] && `(${missingLevels[id]})`) || null;
      }

      return filterCount;
    }

    return null;
  };

  const hasMoreThanOneLocation = locations.length > 1;

  const onSwitchLocationClick = () => setIsModalOpen(true);

  return (
    <>
      <ul className={styles.menu}>
        <li
          className={
            (isMenuActive("Account Summary") && styles.active) || undefined
          }
          onClick={() => onMenuChange("Account Summary")}
        >
          Account Summary
        </li>
        <li
          className={(isMenuActive("Games") && styles.active) || undefined}
          onClick={() => onMenuChange("Games")}
        >
          Games
          {isMenuActive("Games") && (
            <ul className={styles.subMenu}>
              {location.rooms
                .filter(({ id }) => id !== INACTIVE_GAME_ID)
                .map(({ name, id }, i) => (
                  <li
                    key={`${i + 1}-${name}`}
                    onClick={() => {
                      eventTracker(`${name} room selected`);
                      onRoomClick({ roomName: name, roomId: id });
                    }}
                    className={isRoomSelected(id)}
                  >
                    {name} {renderFilterCountPerRoom(id)}
                  </li>
                ))}
            </ul>
          )}
        </li>
        {isMenuActive("Games") && (
          <>
            {!isComparingWithOtherLocations && (
              <li
                onClick={
                  isComparingOldScores
                    ? clearPlayerYesterday
                    : fetchPlayerYesterday
                }
              >
                {isComparingOldScores
                  ? "Clear Comparison"
                  : "Compare with Yesterday"}
              </li>
            )}
            {hasMoreThanOneLocation && !isComparingOldScores && (
              <li
                onClick={
                  isComparingWithOtherLocations
                    ? clearPlayerAllLocations
                    : fetchPlayerAllLocations
                }
              >
                {isComparingWithOtherLocations
                  ? "Clear Comparison"
                  : "Compare Other Locations"}
              </li>
            )}
          </>
        )}
        <li
          className={
            (isMenuActive("Leaderboards") && styles.active) || undefined
          }
          onClick={() => onMenuChange("Leaderboards")}
        >
          Leaderboards
        </li>
        <li
          className={
            (isMenuActive("World Leaderboard") && styles.active) || undefined
          }
          onClick={() => onMenuChange("World Leaderboard")}
        >
          World Leaderboard
        </li>
        <li
          className={(isMenuActive("Badges") && styles.active) || undefined}
          onClick={() => onMenuChange("Badges")}
        >
          Badges
        </li>
        {hasMoreThanOneLocation && (
          <li onClick={onSwitchLocationClick}>Switch Locations</li>
        )}
      </ul>
      <ChooseLocationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        menuActive={menuActive}
      />
    </>
  );
};
