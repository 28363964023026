import styles from "./BackButton.module.scss";
import { BiLeftArrowAlt } from "react-icons/bi";

type BackButtonProps = {
  onClick: () => void;
};

export const BackButton = ({ onClick }: BackButtonProps): JSX.Element => {
  return (
    <button className={styles.button} onClick={onClick}>
      <BiLeftArrowAlt size={20} />
      <span className={styles.text}>BACK</span>
    </button>
  );
};
