// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GamesList_table__AmDe3 {\n  text-align: right;\n  border-collapse: collapse;\n  margin-bottom: 24px; }\n  .GamesList_table__AmDe3 tr,\n  .GamesList_table__AmDe3 th,\n  .GamesList_table__AmDe3 td {\n    border: 1px solid white; }\n  .GamesList_table__AmDe3 th,\n  .GamesList_table__AmDe3 td {\n    padding: 8px; }\n  .GamesList_table__AmDe3 th {\n    text-align: center; }\n\n.GamesList_tableWrapper__kkr1x {\n  display: flex;\n  justify-content: center; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/GamesList.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB,EAAA;EAHrB;;;IAOI,uBAAuB,EAAA;EAP3B;;IAWI,YAAY,EAAA;EAXhB;IAcI,kBAAkB,EAAA;;AAItB;EACE,aAAa;EACb,uBAAuB,EAAA","sourcesContent":[".table {\n  text-align: right;\n  border-collapse: collapse;\n  margin-bottom: 24px;\n  tr,\n  th,\n  td {\n    border: 1px solid white;\n  }\n  th,\n  td {\n    padding: 8px;\n  }\n  th {\n    text-align: center;\n  }\n}\n\n.tableWrapper {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "GamesList_table__AmDe3",
	"tableWrapper": "GamesList_tableWrapper__kkr1x"
};
export default ___CSS_LOADER_EXPORT___;
