import styles from "./ScoreDifference.module.scss";

type ScoreDifferenceProps = {
  score: number;
  topScore: number;
  visible?: boolean;
  positiveSign?: boolean;
};

export const ScoreDifference = ({
  score,
  topScore,
  visible = false,
  positiveSign = false,
}: ScoreDifferenceProps): JSX.Element => {
  const differenceToRecord = topScore ? score - topScore : 0;

  if (differenceToRecord < 0 && visible) {
    if (positiveSign) {
      return (
        <span className={styles.scoreDifferencePositive}>
          (+{differenceToRecord * -1})
        </span>
      );
    }

    return (
      <span className={styles.scoreDifferenceNegative}>
        ({differenceToRecord})
      </span>
    );
  }

  return <></>;
};
