import produce from "immer";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";

type State = {
  lastUpdatedPlayers?: string;
  lastUpdatedLeaderboards?: string;
};

const initialState: State = {};

export const lastUpdatedReducer = produce(
  (state: State, action: Action): State => {
    switch (action.type) {
      case ActionType.FETCH_LAST_UPDATED:
        state.lastUpdatedPlayers = action.payload.players;
        state.lastUpdatedLeaderboards = action.payload.leaderboards;

        return state;
      default:
        return state;
    }
  },
  initialState
);
