import { useCallback } from "react";

import styles from "./Checkbox.module.scss";

import { NewTag } from "../../components/NewTag/NewTag";
import { useAnalyticsEventTracker } from "../../hooks";
import classNames from "classnames";

export type CheckboxProps = {
  label: string;
  value: boolean;
  onValueChange: () => void;
  newTag?: boolean;
  additionalStyles?: string;
  "data-trackid"?: string;
};

export const Checkbox = ({
  label,
  value,
  onValueChange,
  newTag = false,
  additionalStyles,
  "data-trackid": dataTrackId,
}: CheckboxProps): JSX.Element => {
  const { eventTracker } = useAnalyticsEventTracker("checkbox");

  const handleOnValueChange = useCallback(() => {
    // only tracks values that are about to become true
    if (!value && dataTrackId) {
      eventTracker(dataTrackId);
    }
    onValueChange();
  }, [value, dataTrackId]);

  return (
    <div className={classNames(styles.wrapper, additionalStyles)}>
      <input
        className={styles.checkbox}
        id={label}
        type="checkbox"
        checked={value}
        onChange={handleOnValueChange}
      />
      <label htmlFor={label}>{label}</label>
      {newTag && <NewTag className={styles.newTag} />}
    </div>
  );
};
