// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DownArrow_downArrow__UKQM7 {\n  margin-left: 4px;\n  color: red; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Leaderboard/components/DownArrow.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU,EAAA","sourcesContent":[".downArrow {\n  margin-left: 4px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downArrow": "DownArrow_downArrow__UKQM7"
};
export default ___CSS_LOADER_EXPORT___;
