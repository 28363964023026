type TextInputProps = {
  innerRef?: React.LegacyRef<HTMLInputElement>;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const TextInput = ({ innerRef, ...props }: TextInputProps) => {
  return <input ref={innerRef} {...props} />;
};
