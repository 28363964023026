// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GameCardList_wrapper__VP5fh {\n  margin: 24px 0;\n  padding: 0 100px; }\n\n.GameCardList_firstBatch__bQWSS {\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 24px; }\n\n.GameCardList_secondBatch__ivO-X {\n  display: flex;\n  justify-content: flex-start; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/GameCardList.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB,EAAA;;AAGlB;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,2BAA2B,EAAA","sourcesContent":[".wrapper {\n  margin: 24px 0;\n  padding: 0 100px;\n}\n\n.firstBatch {\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 24px;\n}\n\n.secondBatch {\n  display: flex;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "GameCardList_wrapper__VP5fh",
	"firstBatch": "GameCardList_firstBatch__bQWSS",
	"secondBatch": "GameCardList_secondBatch__ivO-X"
};
export default ___CSS_LOADER_EXPORT___;
