import produce from "immer";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { StrategyResponse } from "../responseTypes";

type State = StrategyResponse & {
  error?: string;
  loading: boolean;
};

const initialState: State = {
  guns: [[]],
  level: "",
  targets: [[]],
  loading: false,
};

export const strategyReducer = produce(
  (state: State, action: Action): State => {
    switch (action.type) {
      case ActionType.LOADING_FETCH_STRATEGY_GAME:
        state.loading = action.payload;
        return state;
      case ActionType.FETCH_STRATEGY_GAME_COMPLETE:
        state = { ...state, ...action.payload };
        return state;
      case ActionType.FETCH_STRATEGY_GAME_ERROR:
        state.error = action.payload;
        return state;
      default:
        return state;
    }
  },
  initialState
);
