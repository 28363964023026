// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SortIcon_icon__wn-fb {\n  font-size: 20px; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Badges/SortIcon.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAA","sourcesContent":[".icon {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "SortIcon_icon__wn-fb"
};
export default ___CSS_LOADER_EXPORT___;
