import { ReactNode } from "react";
import classnames from "classnames";
import styles from "./Title.module.scss";

type TitleProps = {
  children: ReactNode;
  additionalStyles?: string;
};

export const Title = ({
  children,
  additionalStyles,
}: TitleProps): JSX.Element => {
  const titleStyles = classnames(styles.title, additionalStyles);
  return <h1 className={titleStyles}>{children}</h1>;
};
