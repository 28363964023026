import styles from "./GamesList.module.scss";

import { LevelRows } from "./LevelRows";
import { getScoreByGame, isPersonalRecord } from "../../../utils";
import { RadioItemValue } from "../../../../../components";
import { useOtherLocations, useTypedSelector } from "../../../../../hooks";
import { Game, Level } from "../../../../../redux/models";
import { CompareToYourselfTable } from "./CompareToYourselfTable";

export type Filters = {
  improveScoreBy: RadioItemValue;
};

type GamesListProps = {
  games: Game[];
} & Filters;

const HARDCODED_COLUMNS = 3;

export const GamesList = ({
  games,
  improveScoreBy,
}: GamesListProps): JSX.Element => {
  const {
    playerInfo: { player, comparePlayers },
    filterScores: {
      showOnlyDifferentLevels,
      showOnlyLevelsToMatchScore,
      showOnlyLevelsToImprove,
      showOnlyMissingLevels,
      showOnlyNotTopScores,
      showOnlyTopScores,
      showScoreDifference,
    },
  } = useTypedSelector(({ playerInfo, filterScores }) => ({
    playerInfo,
    filterScores,
  }));
  const { isComparingWithOtherLocations } = useOtherLocations();

  if (isComparingWithOtherLocations) {
    return <CompareToYourselfTable games={games} />;
  }

  const allPlayers = [player, ...comparePlayers];

  const isComparingWithOtherPlayers = allPlayers.length > 1;

  const filterGameLevels = ({ levels }: Game): Level[] => {
    const filterGameLevels =
      showOnlyDifferentLevels || showOnlyLevelsToMatchScore;
    if (!comparePlayers.length || !filterGameLevels) {
      if (!comparePlayers.length) {
        if (showOnlyLevelsToImprove) {
          return filterLevelsToImproveScore(levels);
        } else if (showOnlyMissingLevels) {
          return filterMissingLevels(levels);
        } else if (showOnlyTopScores) {
          return filterTopScores(levels);
        } else if (showOnlyNotTopScores) {
          return filterNotTopScores(levels);
        }
      }
      return levels;
    }

    if (showOnlyDifferentLevels) {
      return filterDifferentLevels(levels);
    }

    if (showOnlyLevelsToMatchScore) {
      return filterLevelsToMatch(levels);
    }

    return levels;
  };

  const filterTopScores = (levels: Level[]): Level[] => {
    return levels.filter((level) => {
      const playerScore = getScoreByGame(player, level.game_id, level.level_id);
      return isPersonalRecord(playerScore, level.top_score);
    });
  };

  const filterNotTopScores = (levels: Level[]): Level[] => {
    return levels.filter((level) => {
      const playerScore = getScoreByGame(player, level.game_id, level.level_id);
      return !isPersonalRecord(playerScore, level.top_score);
    });
  };

  const filterMissingLevels = (levels: Level[]): Level[] => {
    return levels.filter((level) => {
      const playerScore = getScoreByGame(player, level.game_id, level.level_id);
      return !playerScore;
    });
  };

  const filterLevelsToImproveScore = (levels: Level[]): Level[] => {
    return levels.filter((level) => {
      const playerScore = getScoreByGame(player, level.game_id, level.level_id);
      return (
        playerScore &&
        typeof improveScoreBy === "number" &&
        level.top_score - playerScore >= improveScoreBy
      );
    });
  };

  const filterDifferentLevels = (levels: Level[]): Level[] => {
    return levels.filter((level) => {
      const playerScore = getScoreByGame(player, level.game_id, level.level_id);
      let comparePlayerScore = 0;
      for (let i = 0; i < comparePlayers.length; i++) {
        comparePlayerScore = getScoreByGame(
          comparePlayers[i],
          level.game_id,
          level.level_id
        );
        if (
          (playerScore && !comparePlayerScore) ||
          (!playerScore && comparePlayerScore)
        ) {
          break;
        }
      }

      return (
        (playerScore && !comparePlayerScore) ||
        (!playerScore && comparePlayerScore)
      );
    });
  };

  const filterLevelsToMatch = (levels: Level[]): Level[] => {
    return levels.filter((level) => {
      const playerScore = getScoreByGame(player, level.game_id, level.level_id);
      for (let i = 0; i < comparePlayers.length; i++) {
        if (
          playerScore !==
          getScoreByGame(comparePlayers[i], level.game_id, level.level_id)
        ) {
          return true;
        }
      }

      return false;
    });
  };

  return (
    <>
      {games.map((game, i) => {
        const filteredGameLevels = filterGameLevels(game);
        return (
          <div key={`score-${i}`} className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th colSpan={HARDCODED_COLUMNS + allPlayers.length}>
                    {game.name}
                  </th>
                </tr>
                <tr>
                  <th>Level</th>
                  {!isComparingWithOtherPlayers ? (
                    <>
                      <th>Times Passed</th>
                      <th>Your Score</th>
                    </>
                  ) : (
                    allPlayers.map(({ player_name }, i) => (
                      <th key={`player-${i}`}>{player_name}</th>
                    ))
                  )}
                  <th>Top Score</th>
                </tr>
              </thead>
              <tbody>
                <LevelRows
                  levels={filteredGameLevels}
                  showScoreDifference={showScoreDifference}
                />
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
};
