import styles from "./DownArrow.module.scss";
import { BsCaretDownFill } from "react-icons/bs";

type DownArrowProps = {
  positionDifference: number;
};

export const DownArrow = ({
  positionDifference,
}: DownArrowProps): JSX.Element => {
  return (
    <>
      {positionDifference}
      <BsCaretDownFill className={styles.downArrow} />
    </>
  );
};
