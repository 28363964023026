import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

import styles from "./SortIcon.module.scss";

type Sort = "ASC" | "DESC";

type SortIconProps = {
  sortDirection?: Sort;
};

export const SortIcon = ({ sortDirection }: SortIconProps): JSX.Element => {
  switch (sortDirection) {
    case "ASC":
      return <FaSortDown className={styles.icon} />;
    case "DESC":
      return <FaSortUp className={styles.icon} />;
    default:
      return <FaSort className={styles.icon} />;
  }
};
