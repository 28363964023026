import { Game } from "../redux/models/game";
import { HighScore } from "../redux/models/highScore";
import { Level } from "../redux/models/level";
import { PlayerInfo } from "../redux/models/playerInfo";
import { FilterPerRoom } from "../redux/models/player";
import { Room } from "../redux/models/room";
import {
  getScoreByGame,
  getTimesPassedLevel,
  isPersonalRecord,
} from "../pages/Activate/utils";

export const parsePlayer = (player: {
  data: PlayerInfo;
}): PlayerInfo | null => {
  if (!player.data) {
    return null;
  }

  const data = player.data;
  // // Parse Location
  const _rooms = data.location.rooms;
  const rooms: Room[] = [];
  for (const i in _rooms) {
    const _games = _rooms[i].games;
    const games: Game[] = [];
    for (const j in _games) {
      const _levels = _games[j].levels;
      const levels: Level[] = [];
      for (const l in _levels) {
        levels.push(_levels[l]);
      }
      games.push({ ..._games[j], levels });
    }
    rooms.push({ ...data.location.rooms[i], games });
  }
  data.location.rooms = rooms;

  // Parse Player
  let highScores: HighScore[] = [];
  const _highScores = data.player.high_scores;
  for (const i in _highScores) {
    try {
      const scores = [...(_highScores[i] as any)];
      highScores = [...highScores, ...scores];
    } catch (e) {
      // In case highscore comes as an object instead of an array
      // this example was seen on MegaGrid scores
      const scores: HighScore[] = [];
      for (const j in _highScores[i]) {
        scores.push((_highScores[i] as any)[j]);
      }
      highScores = [...highScores, ...scores];
    }
  }
  data.player.high_scores = highScores;

  const topScores: FilterPerRoom = {};
  const notTopScores: FilterPerRoom = {};
  const missingLevels: FilterPerRoom = {};
  let passedLevels = 0;
  let starsOldRankingSystem = 0;

  // Populates Player Top Scores Count per Room
  data.location.rooms.forEach((room) => {
    topScores[room.id] = 0;
    notTopScores[room.id] = 0;
    missingLevels[room.id] = 0;
    room.games.forEach((game) => {
      game.levels.forEach((level) => {
        const playerScore = getScoreByGame(
          data.player,
          level.game_id,
          level.level_id
        );
        if (isPersonalRecord(playerScore, level.top_score)) {
          topScores[room.id]++;
        } else {
          notTopScores[room.id]++;
        }
        if (playerScore) {
          passedLevels++;
        } else {
          missingLevels[room.id]++;
        }

        const timesPassedLevel = getTimesPassedLevel(
          data.player,
          level.game_id,
          level.level_id
        );
        starsOldRankingSystem +=
          (timesPassedLevel > 0 &&
            10 + (timesPassedLevel - 1) * (level.level_id + 1)) ||
          0;
      });
    });
    data.player.topScores = { ...data.player.topScores, ...topScores };
    data.player.notTopScores = { ...data.player.notTopScores, ...notTopScores };
    data.player.missingLevels = {
      ...data.player.missingLevels,
      ...missingLevels,
    };
    data.player.passedLevels = passedLevels;
    data.player.starsOldRankingSystem = starsOldRankingSystem;
  });

  return data;
};
