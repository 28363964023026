import { MAX_RANK } from "../constants";

type Rank = {
  [key: string]:
    | (
        | {
            stars?: number;
            levelPercentage: number;
          }
        | {
            stars: number;
            levelPercentage?: number;
          }
      ) & { reward: string };
};

const RANKS: Rank = {
  "2": {
    stars: 25,
    reward: "Keychain",
  },
  "3": {
    stars: 150,
    reward: "Activate Water Bottle",
  },
  "4": {
    stars: 500,
    reward: "Basketball",
  },
  "5": {
    stars: 1000,
    reward: "Activate T-Shirt",
  },
  "6": {
    stars: 2000,
    reward: "Activate Hat",
  },
  "7": {
    stars: 3000,
    reward: "Activate Sweater",
  },
  "8": {
    stars: 10000,
    reward: "$250 Activate Gift Card",
  },
};

type NextRankProps = {
  rank: number;
  stars: number;
  levels: number;
  cityLevels: number;
};

export const getNextRank = ({
  cityLevels,
  levels,
  rank,
  stars,
}: NextRankProps): string => {
  if (rank >= MAX_RANK) {
    return "n/a";
  }

  // if (rank < 4) {
  const starsToNextRank = RANKS[rank + 1].stars || 0;
  return `${starsToNextRank - stars} stars`;
  // } else {
  //   const levelsToNextRank =
  //     ((RANKS[rank + 1].levelPercentage || 0) / 100) * cityLevels;
  //   return `${Math.floor(levelsToNextRank - levels)} levels`;
  // }
};

export const getNextRankReward = (nextRank: number) => RANKS[nextRank].reward;
