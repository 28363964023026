import classNames from "classnames";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import ReactToPrint from "react-to-print";

import styles from "./Badges.module.scss";
import { BADGES_LOCAL_STORAGE_KEY } from "./constants";
import { SortIcon } from "./SortIcon";
import { Checkbox } from "../../../../components";
import { useActions, useTypedSelector } from "../../../../hooks";
import {
  getLocalStorageObject,
  setLocalStorageObject,
} from "../../../../utils";

type BadgesProps = {
  playerName: string;
};

export const Badges = ({ playerName }: BadgesProps): JSX.Element => {
  const LOCAL_STORAGE_KEY = `${BADGES_LOCAL_STORAGE_KEY}_${playerName}`;

  const [badgesLocalStorage, setBadgesLocalStorage] = useState(
    getLocalStorageObject(LOCAL_STORAGE_KEY)
  );

  const tableRef = useRef<HTMLDivElement>(null);
  const [isDisplayRiddleSolution, setIsDisplayRiddleSolution] = useState(false);
  const [filterCompleteBadges, setFilterCompleteBadges] = useState(false);
  const [filterIncompleteBadges, setFilterIncompleteBadges] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [importBadgesJSON, setImportBadgesJSON] = useState("");

  const { fetchBadges, sortBadgesByTitle, sortBadgesByStars, fakeAPICall } =
    useActions();
  const { isLoading, badges, sortByTitles, sortByStars } = useTypedSelector(
    (state) => state.badge
  );

  useEffect(() => {
    fetchBadges();
  }, [fetchBadges]);

  useEffect(() => {
    setLocalStorageObject(LOCAL_STORAGE_KEY, badgesLocalStorage || {});
  }, [badgesLocalStorage, LOCAL_STORAGE_KEY]);

  if (isLoading) {
    return <>LOADING...</>;
  }

  const clickableTitle = classNames(styles.title, styles.clickable);

  const onBadgeRowClick = (title: string) => () => {
    const _badgesLocalStorage = { ...badgesLocalStorage };
    if (hasBadge(title)) {
      delete _badgesLocalStorage[title];
      setBadgesLocalStorage(_badgesLocalStorage);
    } else {
      setBadgesLocalStorage({ ..._badgesLocalStorage, [title]: title });
    }
  };

  const hasBadge = (title: string): boolean => {
    return Boolean((badgesLocalStorage || {})[title]);
  };

  const displayBadgesCount = (): string => {
    let badgeCount = 0;
    for (const prop in badgesLocalStorage) {
      if (badgesLocalStorage.hasOwnProperty(prop)) {
        badgeCount++;
      }
    }
    return `${
      filterIncompleteBadges ? badges.length - badgeCount : badgeCount
    }/${badges.length}`;
  };

  const handleFilterCompleteBadges = () => {
    if (!filterCompleteBadges) {
      setFilterIncompleteBadges(false);
    }

    setFilterCompleteBadges(!filterCompleteBadges);
  };

  const handleFilterIncompleteBadges = () => {
    if (!filterIncompleteBadges) {
      setFilterCompleteBadges(false);
    }

    setFilterIncompleteBadges(!filterIncompleteBadges);
  };

  const onOpenModalClick = () => {
    setIsModalOpen(true);
    setImportBadgesJSON("");
  };

  const onCloseModalClick = () => {
    setIsModalOpen(false);
  };

  const onImportBadgesClick = () => {
    fakeAPICall(`/badges/import/${playerName}`);
    try {
      const parsedBadges = JSON.parse(importBadgesJSON);

      setBadgesLocalStorage(parsedBadges);

      onCloseModalClick();
    } catch (err) {
      alert("Invalid JSON. Verify what you typed and try again.");
    }
  };

  const onExportBadgesClick = () => {
    fakeAPICall(`/badges/export/${playerName}`);
    const badges = badgesLocalStorage || {};
    const blob = new Blob([JSON.stringify(badges)], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `badges_${playerName}.txt`);
  };

  // used to display the solution for riddles
  const onDescriptionClick = (
    e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>
  ) => {
    if (e.detail === 3) {
      setIsDisplayRiddleSolution(!isDisplayRiddleSolution);
    }
  };

  Modal.setAppElement("#root");

  return (
    <div>
      <div className={styles.actionsWrapper}>
        <div className={styles.filtersWrapper}>
          <p>* This is not the full list of badges</p>
          <p>Filters</p>
          <Checkbox
            value={filterCompleteBadges}
            onValueChange={handleFilterCompleteBadges}
            label="Complete"
            data-trackid="filter complete badges"
          />
          <Checkbox
            value={filterIncompleteBadges}
            onValueChange={handleFilterIncompleteBadges}
            label="Incomplete"
            data-trackid="filter incomplete badges"
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <button className={styles.button} onClick={onOpenModalClick}>
            Import
          </button>
          <button className={styles.button} onClick={onExportBadgesClick}>
            Export
          </button>
          <ReactToPrint
            onAfterPrint={() => {
              fakeAPICall(`/badges/print/${playerName}`);
            }}
            trigger={() => {
              return <button className={styles.button}>Print</button>;
            }}
            content={() => tableRef.current}
          />
        </div>
      </div>
      <h1>Badges {displayBadgesCount()}</h1>
      <div ref={tableRef} className={styles.wrapper}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th onClick={sortBadgesByTitle} className={clickableTitle}>
                Title <SortIcon sortDirection={sortByTitles} />
              </th>
              <th className={styles.title} onClick={onDescriptionClick}>
                Description
              </th>
              <th onClick={sortBadgesByStars} className={clickableTitle}>
                Stars <SortIcon sortDirection={sortByStars} />
              </th>
            </tr>
          </thead>
          <tbody>
            {badges.map(({ description, stars, title, solution, isNew }) => {
              const _hasBadge = hasBadge(title);
              const selectedClassName = classNames(
                styles.row,
                _hasBadge && styles.selected
              );

              if (filterCompleteBadges) {
                if (!_hasBadge) {
                  return null;
                }
              } else if (filterIncompleteBadges) {
                if (_hasBadge) {
                  return null;
                }
              }

              return (
                <tr
                  key={title}
                  className={selectedClassName}
                  onClick={onBadgeRowClick(title)}
                >
                  <td className={styles.isNew}>{isNew && "✨"}</td>
                  <td>{title}</td>
                  <td>
                    {isDisplayRiddleSolution
                      ? solution || description
                      : description}
                  </td>
                  <td className={styles.starsColumn}>{stars}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        contentLabel="Import"
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick
        onRequestClose={onCloseModalClick}
        className={styles.modal}
      >
        <div>
          <textarea
            onChange={(e) => setImportBadgesJSON(e.target.value)}
            className={styles.textarea}
            placeholder="Paste here..."
            value={importBadgesJSON}
          />
        </div>
        <div className={styles.modalActionsWrapper}>
          <button onClick={onCloseModalClick}>Cancel</button>
          <button onClick={onImportBadgesClick}>Import</button>
        </div>
      </Modal>
    </div>
  );
};
