import styles from "./PositionDifference.module.scss";
import { UpArrow } from "./UpArrow";
import { DownArrow } from "./DownArrow";
import { SamePosition } from "./SamePosition";

type PositionDifferenceProps = {
  positionDifference?: number;
};

export const PositionDifference = ({
  positionDifference,
}: PositionDifferenceProps): JSX.Element => {
  return (
    <span className={styles.position}>
      {!positionDifference ? (
        <SamePosition />
      ) : positionDifference > 0 ? (
        <UpArrow positionDifference={positionDifference} />
      ) : (
        <DownArrow positionDifference={positionDifference} />
      )}
    </span>
  );
};
