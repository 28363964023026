import { Dispatch } from "react";
import { Action } from "../actions/activate";
import { ActionType } from "../actionTypes";
import { RootState } from "../reducers";

const disableMultiPlayerFilters = (
  dispatch: Dispatch<Action>,
  {
    filterScores: { showOnlyLevelsToMatchScore, showOnlyDifferentLevels },
  }: RootState
) => {
  showOnlyLevelsToMatchScore &&
    dispatch({
      type: ActionType.TOGGLE_SHOW_ONLY_LEVELS_TO_MATCH_SCORE,
      payload: false,
    });
  showOnlyDifferentLevels &&
    dispatch({
      type: ActionType.TOGGLE_SHOW_ONLY_DIFFERENT_LEVELS,
      payload: false,
    });
};

const disableSinglePlayerFilters = (
  dispatch: Dispatch<Action>,
  {
    filterScores: {
      showOnlyLevelsToImprove,
      showOnlyMissingLevels,
      showOnlyTopScores,
      showOnlyNotTopScores,
    },
  }: RootState
) => {
  showOnlyLevelsToImprove &&
    dispatch({
      type: ActionType.TOGGLE_SHOW_ONLY_LEVELS_TO_IMPROVE,
      payload: false,
    });
  showOnlyMissingLevels &&
    dispatch({
      type: ActionType.TOGGLE_SHOW_ONLY_MISSING_LEVELS,
      payload: false,
    });
  showOnlyTopScores &&
    dispatch({
      type: ActionType.TOGGLE_SHOW_ONLY_TOP_SCORES,
      payload: false,
    });
  showOnlyNotTopScores &&
    dispatch({
      type: ActionType.TOGGLE_SHOW_ONLY_NOT_TOP_SCORES,
      payload: false,
    });
};

const disableSingleAndMultiPlayerFilters = (
  dispatch: Dispatch<Action>,
  state: RootState,
  currentFilterToggleValue: boolean
) => {
  !currentFilterToggleValue && disableSinglePlayerFilters(dispatch, state);
  !currentFilterToggleValue && disableMultiPlayerFilters(dispatch, state);
};

export const toggleMultiGameView = () => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionType.TOGGLE_MULTI_GAME_VIEW });
};

export const toggleShowScoreDifferenceGameLevel =
  () => (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.TOGGLE_SHOW_SCORE_DIFFERENCE_GAME_LEVEL });
  };

export const toggleShowTotalScorePerGame =
  () => (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.TOGGLE_SHOW_TOTAL_SCORE_PER_GAME });
  };

export const toggleShowOnlyDifferentLevels = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState();
    disableSingleAndMultiPlayerFilters(
      dispatch,
      state,
      state.filterScores.showOnlyDifferentLevels
    );
    dispatch({ type: ActionType.TOGGLE_SHOW_ONLY_DIFFERENT_LEVELS });
  };
};

export const toggleShowOnlyLevelsToMatchScore = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState();
    disableSingleAndMultiPlayerFilters(
      dispatch,
      state,
      state.filterScores.showOnlyLevelsToMatchScore
    );

    dispatch({ type: ActionType.TOGGLE_SHOW_ONLY_LEVELS_TO_MATCH_SCORE });
  };
};

export const toggleShowOnlyTopScores = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState();
    disableSingleAndMultiPlayerFilters(
      dispatch,
      state,
      state.filterScores.showOnlyTopScores
    );

    dispatch({ type: ActionType.TOGGLE_SHOW_ONLY_TOP_SCORES });
  };
};

export const toggleShowOnlyNotTopScores = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState();
    disableSingleAndMultiPlayerFilters(
      dispatch,
      state,
      state.filterScores.showOnlyNotTopScores
    );

    dispatch({ type: ActionType.TOGGLE_SHOW_ONLY_NOT_TOP_SCORES });
  };
};

export const toggleShowOnlyMissingLevels = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState();
    disableSingleAndMultiPlayerFilters(
      dispatch,
      state,
      state.filterScores.showOnlyMissingLevels
    );

    dispatch({ type: ActionType.TOGGLE_SHOW_ONLY_MISSING_LEVELS });
  };
};

export const toggleShowOnlyLevelsToImprove = () => {
  return (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const state = getState();
    disableSingleAndMultiPlayerFilters(
      dispatch,
      state,
      state.filterScores.showOnlyLevelsToImprove
    );

    dispatch({ type: ActionType.TOGGLE_SHOW_ONLY_LEVELS_TO_IMPROVE });
  };
};
