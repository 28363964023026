// import axios from "axios";
import { Dispatch } from "react";

import { Action } from "../actions";
import { ActionType } from "../actionTypes";
// import { Album } from "../models/album";

export const fetchAlbums = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.FETCH_ALBUMS });
    try {
      // const { data }: { data: Album[] } = await axios.get(
      //   "https://jsonplaceholder.typicode.com/albums"
      // );
      // dispatch({
      //   type: ActionType.FETCH_ALBUMS_COMPLETE,
      //   payload: data,
      // });
    } catch (err) {
      console.log("do nothing as of now");
    }
  };
};
