// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPlayer_wrapper__hOray {\n  min-height: 55vh;\n  display: flex;\n  padding-top: 50px;\n  align-items: center; }\n\n@media (max-width: 960px) {\n  .SearchPlayer_searchInput__Pp0li {\n    display: flex;\n    flex-direction: column; } }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/SearchPlayer/SearchPlayer.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,mBAAmB,EAAA;;AAInB;EADF;IAEI,aAAa;IACb,sBAAsB,EAAA,EAEzB","sourcesContent":["@import \"../../../../styles/breakpoints.module.scss\";\n\n.wrapper {\n  min-height: 55vh;\n  display: flex;\n  padding-top: 50px;\n  align-items: center;\n}\n\n.searchInput {\n  @media (max-width: $breakpointTablet) {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SearchPlayer_wrapper__hOray",
	"searchInput": "SearchPlayer_searchInput__Pp0li"
};
export default ___CSS_LOADER_EXPORT___;
