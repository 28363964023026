// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GameCard_wrapper__YC\\+n3 {\n  min-width: 23%;\n  text-transform: uppercase;\n  height: 50px;\n  border-radius: 4px;\n  background-color: #1e1e1e;\n  box-shadow: 5px 5px black;\n  color: white;\n  border: none;\n  font-size: 16px;\n  cursor: pointer;\n  margin-left: 20px; }\n  .GameCard_wrapper__YC\\+n3:hover {\n    background-color: rgba(225, 245, 235, 0.3); }\n\n.GameCard_selectedGame__KIva\\+ {\n  border: 1px solid white; }\n", "",{"version":3,"sources":["webpack://./src/pages/Activate/components/Room/components/GameCard.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;EACf,iBAAiB,EAAA;EAXnB;IAcI,0CAA0C,EAAA;;AAI9C;EACE,uBAAuB,EAAA","sourcesContent":[".wrapper {\n  min-width: 23%;\n  text-transform: uppercase;\n  height: 50px;\n  border-radius: 4px;\n  background-color: #1e1e1e;\n  box-shadow: 5px 5px black;\n  color: white;\n  border: none;\n  font-size: 16px;\n  cursor: pointer;\n  margin-left: 20px;\n\n  &:hover {\n    background-color: rgba(225, 245, 235, 0.3);\n  }\n}\n\n.selectedGame {\n  border: 1px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "GameCard_wrapper__YC+n3",
	"selectedGame": "GameCard_selectedGame__KIva+"
};
export default ___CSS_LOADER_EXPORT___;
