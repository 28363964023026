import axios from "axios";

// PROD
export const baseURL = process.env.REACT_APP_SCORES_API;

// local testing
// export const baseURL = `http://localhost:${process.env.REACT_APP_SCORES_API_PORT}`;

export const activateAPI = axios.create({
  baseURL,
});
