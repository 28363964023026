import { Fragment, useState } from "react";
import { ScoreDifference } from "./ScoreDifference";
import { TotalScorePerGame } from "./TotalScorePerGame";
import {
  isPersonalRecord,
  getScoreByGame,
  getTimesPassedLevel,
} from "../../../utils";
import { useCompareOldScores, useTypedSelector } from "../../../../../hooks/";
import { Level } from "../../../../../redux/models";
import styles from "./LevelRows.module.scss";
import {
  isGameLevelOnMonthlyScore,
  setMonthlyScore,
} from "../../../../../utils/monthlyScore";

type LevelRowsProps = {
  levels: Level[];
  showScoreDifference: boolean;
};

export const LevelRows = ({
  levels,
  showScoreDifference,
}: LevelRowsProps): JSX.Element => {
  const { player, comparePlayers, playerYesterday, location } =
    useTypedSelector(({ playerInfo }) => playerInfo);
  const { isComparingOldScores } = useCompareOldScores();
  const [forceRefresh, setForceRefresh] = useState(false);

  const allPlayers = [player, ...comparePlayers];
  const isComparingWithOtherPlayers = allPlayers.length > 1;

  const onScoreRowClick = (gameId: number, levelId: number): void => {
    setForceRefresh(!forceRefresh);
    setMonthlyScore({ player, location, gameId, levelId });
  };

  return (
    <>
      {levels.map((level) => {
        let hasPersonalRecord = false;
        return (
          <tr
            key={`level-${level.id}`}
            className={
              !isComparingWithOtherPlayers &&
              isGameLevelOnMonthlyScore({
                player,
                location,
                gameId: level.game_id,
                levelId: level.level_id,
              })
                ? styles.monthlyScore
                : undefined
            }
            onClick={() =>
              !isComparingWithOtherPlayers &&
              onScoreRowClick(level.game_id, level.level_id)
            }
          >
            <td>{level.level_id + 1}</td>
            {allPlayers.map((player, i) => {
              const currentScore = getScoreByGame(
                player,
                level.game_id,
                level.level_id
              );

              const yesterdayScore = getScoreByGame(
                playerYesterday || null,
                level.game_id,
                level.level_id
              );

              const timesPassed = getTimesPassedLevel(
                player,
                level.game_id,
                level.level_id
              );
              const yesterdayTimesPassed = getTimesPassedLevel(
                playerYesterday || null,
                level.game_id,
                level.level_id
              );

              if (!hasPersonalRecord) {
                hasPersonalRecord = isPersonalRecord(
                  currentScore,
                  level.top_score
                );
              }
              return (
                <Fragment key={`score-${i}`}>
                  {!isComparingWithOtherPlayers && (
                    <td>
                      {timesPassed}
                      {isComparingOldScores && (
                        <ScoreDifference
                          score={yesterdayTimesPassed}
                          topScore={timesPassed}
                          visible
                          positiveSign
                        />
                      )}
                    </td>
                  )}
                  <td
                    className={
                      (isPersonalRecord(currentScore, level.top_score) &&
                        styles.personalRecord) ||
                      undefined
                    }
                  >
                    {currentScore}{" "}
                    {isComparingOldScores ? (
                      <ScoreDifference
                        score={yesterdayScore}
                        topScore={currentScore}
                        visible
                        positiveSign
                      />
                    ) : (
                      <ScoreDifference
                        score={currentScore}
                        topScore={level.top_score}
                        visible={showScoreDifference}
                      />
                    )}
                  </td>
                </Fragment>
              );
            })}
            <td
              className={
                (hasPersonalRecord && styles.personalRecord) || undefined
              }
            >
              {level.top_score || 0}
            </td>
          </tr>
        );
      })}
      <TotalScorePerGame levels={levels} players={allPlayers} />
    </>
  );
};
