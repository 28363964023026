import { useState, useCallback, useEffect } from "react";
import { VscStarFull } from "react-icons/vsc";

import styles from "./Activate.module.scss";

import { BackButton } from "./components/BackButton/BackButton";
import { Badges } from "./components/Badges/Badges";
import { Summary } from "./components/Summary/Summary";
import { Room } from "./components/Room/Room";
import { Leaderboard } from "./components/Leaderboard/Leaderboard";
import { WorldLeaderboard } from "./components/Leaderboard/WorldLeaderboard";
import { SearchPlayer } from "./components/SearchPlayer/SearchPlayer";
import { BindedPlayers } from "./components/BindedPlayers/BindedPlayers";

import {
  MenuActive,
  RoomNameAndId,
  SideMenu,
} from "./components/SideMenu/SideMenu";
import { useActions, useTypedSelector } from "../../hooks";
import { LoadingDiv } from "../../components/LoadingDiv/LoadingDiv";
import { ChooseLocationModal } from "./components/ChooseLocationModal/ChooseLocationModal";
import { LoadingSpinnerOverlay } from "../../components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";

export const Activate = (): JSX.Element | null => {
  const {
    playerInfo: {
      player,
      location,
      isLoading,
      locations,
      isLoadingCompareAction,
    },
    lastUpdated: { lastUpdatedLeaderboards, lastUpdatedPlayers },
  } = useTypedSelector(({ playerInfo, lastUpdated }) => ({
    playerInfo,
    lastUpdated,
  }));

  const { clearPreviousSearch, clearPlayerYesterday, clearPlayerAllLocations } =
    useActions();

  const [menuActive, setMenuActive] = useState<MenuActive>("Account Summary");
  const [room, setRoom] = useState<RoomNameAndId>({ roomName: "Hoops" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onRoomClick = useCallback((room: RoomNameAndId) => {
    setRoom(room);
  }, []);

  useEffect(() => {
    if (locations.length && !player.player_name) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [locations, player]);

  const renderContent = (): JSX.Element => {
    switch (menuActive) {
      case "Account Summary":
        return <Summary player={player} location={location} />;
      case "Games":
        const _room = location.rooms.find(
          (r) =>
            r.id === room.roomId || (!room.roomId && r.name === room.roomName)
        );
        return <Room room={_room} location={location.name} />;
      case "Leaderboards":
        return <Leaderboard />;
      case "World Leaderboard":
        return <WorldLeaderboard />;
      case "Badges":
        return <Badges playerName={player.player_name} />;
      default:
        return <></>;
    }
  };

  const handleBackButtonClick = useCallback(() => {
    if (menuActive !== "Account Summary") {
      setMenuActive("Account Summary");
    } else {
      clearPlayerAllLocations();
      clearPlayerYesterday();
      clearPreviousSearch();
    }
  }, [
    menuActive,
    clearPreviousSearch,
    clearPlayerAllLocations,
    clearPlayerYesterday,
  ]);

  return (
    <div className={styles.backgroundImage}>
      <ChooseLocationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // TODO: needs to be worked on in the future
        // showDontAskAgainCheckbox
        menuActive={menuActive}
      />
      {lastUpdatedPlayers && lastUpdatedLeaderboards && (
        <div className={styles.lastUpdated}>
          <p>Players last updated: {lastUpdatedPlayers}</p>
          <p>Leaderboards last updated: {lastUpdatedLeaderboards}</p>
        </div>
      )}
      <div className={styles.wrapper}>
        {player.player_name ? (
          <div>
            {isLoadingCompareAction && <LoadingSpinnerOverlay />}
            <BackButton onClick={handleBackButtonClick} />
            <div className={styles.wrapperPlayerBasicInfo}>
              <div>{player.player_name}</div>
              <div className={styles.starWrapper}>
                <VscStarFull className={styles.star} /> {player.stars} Stars
              </div>
            </div>
            <hr />
            <div className={styles.content}>
              <SideMenu
                menuActive={menuActive}
                onRoomClick={onRoomClick}
                room={room}
                setMenuActive={setMenuActive}
              />
              <div className={styles.information}>{renderContent()}</div>
            </div>
          </div>
        ) : (
          <>
            {isLoading ? (
              <LoadingDiv />
            ) : (
              <>
                <SearchPlayer />
                <BindedPlayers />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
